import axios from 'axios';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');
const { statsInitialState } = require('./states');

const initialState = statsInitialState;

export const fetchStatsAsync = createAsyncThunk('stats/fetchAsync', async (input, thunkApi) => {
  const { successCallback, errorCallback } = input;
  const { getState, rejectWithValue } = thunkApi;
  const { user } = getState();
  const config = {
    headers: {
      authorization: `Bearer ${user.accessToken}`,
    },
  };
  try {
    const stats = await axios.get(`${process.env.REACT_APP_API_END_POINT}statistics/getAdminDashboardStats`, config);
    successCallback();
    return stats.data.content;
  } catch (error) {
    errorCallback(error);
    return rejectWithValue(error.response.data);
  }
});

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStatsAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchStatsAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.value = action.payload;
    });
    builder.addCase(fetchStatsAsync.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default statsSlice.reducer;
