import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Edit, Trash } from 'iconsax-react';
import { useToasts } from 'react-toast-notifications';
import CustomModal from '../Adapters/CustomModal';
import UpdateCategoryModalContent from '../UpdateCategoryModalContent';
import theme from '../../utils/theme';
import { errorLogger } from '../../utils/helpers/component';
import apiInstance from '../../utils/apiInstance';
import { successToastOptions } from '../../utils/constants';
import { fetchCategoriesAsync } from '../../utils/redux/categoriesSlice';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #f1f2f2;
`;

const Title = styled.h3`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  line-height: ${(props) => props.theme.lineHeights['text-base']};
  font-weight: 400;
  padding: 15px 0;
`;

const Span = styled.span`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const CategoryItem = (props) => {
  const { category } = props;
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [updateCategoryModal, setUpdateCategoryModal] = useState(false);

  const handleDelete = async () => {
    try {
      const deleteCategory = window.confirm('Do you really want to delete this Category');
      if (deleteCategory === true) {
        await apiInstance.patch(`categories/update/${category?._id}`, { is_deleted: true });
        addToast('Category Deleted Successfully', successToastOptions);
        dispatch(
          fetchCategoriesAsync({
            successCallback: () => {},
            errorCallback: (error) => {
              errorLogger(error, addToast);
            },
            value: { config: {} },
          })
        );
      }
    } catch (error) {
      errorLogger(error, addToast, () => {});
    }
  };

  return (
    <Container>
      <Title>{category?.category}</Title>
      <Span>
        <Edit size="18" style={{ cursor: 'pointer' }} onClick={() => setUpdateCategoryModal(true)} />
        <Trash size="20" color={theme.colors.error.main} style={{ cursor: 'pointer' }} onClick={handleDelete} />
      </Span>
      <CustomModal
        open={updateCategoryModal}
        handleClose={() => {
          setUpdateCategoryModal(false);
        }}
        heading="Update Category"
        containerStyle={{ minWidth: '600px' }}
      >
        <UpdateCategoryModalContent
          categoryData={category}
          handleClose={() => {
            setUpdateCategoryModal(false);
          }}
        />
      </CustomModal>
    </Container>
  );
};

CategoryItem.propTypes = {
  category: PropTypes.object,
};

export default CategoryItem;
