import styled from 'styled-components';
import PropTypes from 'prop-types';

import variants from '../../../utils/variants';
import theme from '../../../utils/theme';

const Container = styled.div``;

const Button = styled.button`
  transition: all ease-in-out 0.25s;
  font-family: ${(props) => props.theme.fontFamilies.lato};
  &:hover {
    filter: brightness(95%);
    box-shadow: none;
  }
`;

const CustomButton = ({ variant, type, loading, customStyle, children, onClick }) => {
  const getButtonStyle = (variant) => {
    switch (variant) {
      case variants.primary:
        return {
          width: 'auto',
          height: 'auto',
          padding: '5px 20px',
          backgroundColor: theme.colors.primary.main,
          outline: 'none',
          fontFamily: 'lato, sans-serif',
          cursor: 'pointer',
          fontSize: theme.fontSizes.fontSizes,
          border: 'none',
          color: '#fff',
          boxShadow: '0px 4px 8px rgba(117, 131, 142, 0.04), 0px 8px 8px rgba(52, 60, 68, 0.1)',
        };
      case variants.secondary:
        return {
          width: 'auto',
          height: 'auto',
          padding: '5px 20px',
          backgroundColor: '#fff',
          outline: 'none',
          fontFamily: 'lato, sans-serif',
          cursor: 'pointer',
          fontSize: theme.fontSizes.fontSizes,
          border: 'none',
          color: '#fff',
          boxShadow: `0px 8px 16px 0px ${theme.colors.secondary.main}`,
        };
      case variants.disabled:
        return {
          width: 'auto',
          height: 'auto',
          padding: '5px 20px',
          backgroundColor: '#E4E4E4',
          fontSize: theme.fontSizes.fontSizes,
          outline: 'none',
          fontFamily: 'lato, sans-serif',
          pointerEvents: 'none',
          border: 'none',
          cursor: 'not-allowed',
        };
      default:
        break;
    }
  };

  return (
    <Container>
      {loading ? (
        <Button type="button" style={{ ...getButtonStyle(variants.disabled), ...customStyle }}>
          <i
            className="fas fa-spinner fa-spin"
            style={{ color: theme.colors.miscellaneous.white, fontSize: 'inherit' }}
          />
        </Button>
      ) : (
        <Button type={type} onClick={onClick} style={{ ...getButtonStyle(variant), ...customStyle }}>
          {children}
        </Button>
      )}
    </Container>
  );
};

CustomButton.propTypes = {
  variant: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
  customStyle: PropTypes.object,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

CustomButton.defaultProps = {
  variant: '',
  type: 'submit',
  loading: false,
  customStyle: {},
  children: '',
  onClick: () => {},
};

export default CustomButton;
