import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { StyledBodyTableCell, StyledTableBodyRow } from '../../utils/styledComponents';
import theme from '../../utils/theme';

const bodyFirstTableCellStyle = {
  color: theme.colors.secondary.darker,
  fontFamily: theme.fontFamilies.lato,
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: '500',
  borderRadius: '8px 0 0 8px',
  border: 'none',
  padding: '25px 16px',
};

const bodyLastTableCellStyle = {
  color: theme.colors.secondary.darker,
  fontFamily: theme.fontFamilies.lato,
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: '500',
  borderRadius: '0 8px 8px 0',
  border: 'none',
  padding: '25px 16px',
};

const bodyTableCellStyle = {
  color: theme.colors.secondary.darker,
  fontFamily: theme.fontFamilies.lato,
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: '500',
  border: 'none',
  padding: '25px 16px',
};

const CustomerItem = (props) => {
  const { item, page, rowsPerPage, index } = props;
  const bookings = useSelector((state) => state?.bookings);

  const customerBookings = bookings?.value?.filter((booking) => booking?.user_id === item?._id);

  return (
    <StyledTableBodyRow style={{ backgroundColor: theme.backgroundColors.white, borderRadius: '8px' }}>
      <StyledBodyTableCell sx={bodyFirstTableCellStyle} align="left">
        {page * rowsPerPage + index + 1}
      </StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle} align="left">
        {item?.first_name} {item?.last_name}
      </StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle} align="left">
        {item?.mobile_number}
      </StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle} align="left">
        {item?.email}
      </StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle} align="left">
        {item?.vehicles?.length}
      </StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyLastTableCellStyle} align="left">
        {customerBookings?.length}
      </StyledBodyTableCell>
    </StyledTableBodyRow>
  );
};

CustomerItem.propTypes = {
  item: PropTypes.object,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  index: PropTypes.number,
};

export default CustomerItem;
