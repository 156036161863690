import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Edit, Trash } from 'iconsax-react';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';

import UpdateTypeModalContent from '../UpdateTypeModalContent';
import CustomModal from '../Adapters/CustomModal';
import theme from '../../utils/theme';
import apiInstance from '../../utils/apiInstance';
import { successToastOptions } from '../../utils/constants';
import { fetchTypesAsync } from '../../utils/redux/typesSlice';
import { errorLogger } from '../../utils/helpers/component';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #f1f2f2;
`;

const Title = styled.h3`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  line-height: ${(props) => props.theme.lineHeights['text-base']};
  font-weight: 400;
  padding: 15px 0;
`;

const Span = styled.span`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const TypeItem = (props) => {
  const { type } = props;
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [updateTypeModal, setUpdateTypeModal] = useState(false);

  const handleDelete = async () => {
    try {
      const deleteType = window.confirm('Do you really want to delete this Service Type');
      if (deleteType === true) {
        await apiInstance.patch(`types/update/${type?._id}`, { is_deleted: true });
        addToast('Deleted Type Successfully', successToastOptions);
        dispatch(
          fetchTypesAsync({
            successCallback: () => {},
            errorCallback: (error) => {
              errorLogger(error, addToast);
            },
            value: { config: {} },
          })
        );
      }
    } catch (error) {
      errorLogger(error, addToast, () => {});
    }
  };

  return (
    <Container>
      <Title>{type?.type}</Title>
      <Span>
        <Edit
          size="18"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setUpdateTypeModal(true);
          }}
        />
        <Trash size="20" color={theme.colors.error.main} onClick={handleDelete} style={{ cursor: 'pointer' }} />
      </Span>
      <CustomModal
        open={updateTypeModal}
        handleClose={() => {
          setUpdateTypeModal(false);
        }}
        heading="Update Type"
        containerStyle={{ minWidth: '600px' }}
      >
        <UpdateTypeModalContent
          typeData={type}
          handleClose={() => {
            setUpdateTypeModal(false);
          }}
        />
      </CustomModal>
    </Container>
  );
};

TypeItem.propTypes = {
  type: PropTypes.object,
};

export default TypeItem;
