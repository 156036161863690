import { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DASHBOARD_SALES_GRAPH_FILTERS } from '../../utils/constants';
import CustomDropDown from '../Adapters/CustomDropDown';
import theme from '../../utils/theme';

const Container = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div``;

const Right = styled.div``;

const Heading = styled.h5`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-lg']};
  font-weight: 700;
  line-height: ${(props) => props.theme.lineHeights['text-2xl']};
`;

const Description = styled.p`
  color: ${(props) => props.theme.colors.secondary.light};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-sm']};
`;

const SalesGraphHeader = (props) => {
  const { salesGraphFilter, setSalesGraphFilter } = props;

  const handleSalesGraphFilterChange = (values) => {
    setSalesGraphFilter(values[0]);
  };

  const getDescriptionTitle = useMemo(
    () => DASHBOARD_SALES_GRAPH_FILTERS.filter((filter) => filter.title === salesGraphFilter.title)[0].name,
    [salesGraphFilter]
  );

  return (
    <Container>
      <Left>
        <Heading>Sales Graph</Heading>
        <Description>Last Five {getDescriptionTitle}</Description>
      </Left>
      <Right>
        <CustomDropDown
          options={DASHBOARD_SALES_GRAPH_FILTERS}
          onChange={handleSalesGraphFilterChange}
          values={[salesGraphFilter]}
          style={{ backgroundColor: theme.backgroundColors.white, borderRadius: '10px', padding: '10px 10px' }}
        />
      </Right>
    </Container>
  );
};

SalesGraphHeader.propTypes = {
  salesGraphFilter: PropTypes.object,
  setSalesGraphFilter: PropTypes.func,
};

export default SalesGraphHeader;
