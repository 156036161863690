import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Line } from '@ant-design/plots';
import theme from '../../utils/theme';

const Container = styled.div`
  height: auto;
  padding: 15px;
  background-color: #141414;
  border-radius: 16px;
`;

const SalesGraphContent = (props) => {
  const { data } = props;
  const config = {
    data,
    height: 300,
    xField: 'formattedDate',
    yField: 'revenue',
    xAxis: {
      tickCount: 5,
      title: {
        text: 'Date',
      },
    },
    yAxis: {
      title: {
        text: 'Revenue',
      },
    },
    label: {},
    color: '#FB5012',
    point: {
      size: 5,
      shape: 'custom-point',
      style: {
        fill: 'black',
        stroke: '#FB5012',
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: theme.colors.miscellaneous.green,
          fill: theme.colors.miscellaneous.green,
        },
      },
    },
    interactions: [
      {
        type: 'custom-marker-interaction',
      },
    ],
  };
  return (
    <Container>
      <Line {...config} />
    </Container>
  );
};

SalesGraphContent.propTypes = {
  data: PropTypes.array,
};

export default SalesGraphContent;
