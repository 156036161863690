import { Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';

import apiInstance from '../../utils/apiInstance';
import { successToastOptions } from '../../utils/constants';
import { convertHashMapToArray, errorLogger } from '../../utils/helpers/component';
import { CATEGORY, TYPE, _ID } from '../../utils/localization';
import { servicesRoute } from '../../utils/redirects';
import { closeCreateServiceModal } from '../../utils/redux/modalSlice';
import { fetchServicesAsyncThunk } from '../../utils/redux/servicesSlice';
import theme from '../../utils/theme';
import CustomButton from '../Adapters/CustomButton';
import CustomDropDown from '../Adapters/CustomDropDown';
import CustomInput from '../Adapters/CustomInput';

const Container = styled.div``;

const Form = styled.form`
  width: 100%;
`;

const Lable = styled.p`
  margin-bottom: 15px;
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  font-weight: 600;
`;

const TextBox = styled.textarea`
  width: 100%;
  background-color: ${(props) => props.theme.backgroundColors.blueCyan1};
  border: ${(props) => `2px solid ${props.theme.colors.secondary.lighter}`};
  border-radius: 5px;
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  padding: 0 5px;
  &:focus {
    outline: none;
  }
`;

const buttonStyle = {
  backgroundColor: theme.colors.primary.main,
  border: 'none',
  boxShadow:
    '0px 109.322px 87.4576px rgba(41, 72, 152, 0.05), 0px 70.8569px 51.2194px rgba(41, 72, 152, 0.037963), 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 21.8644px 14.2119px rgba(41, 72, 152, 0.025), 0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296), 0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037)',
  padding: '12px 40px',
  borderRadius: '8px',
  color: theme.colors.miscellaneous.white,
  fontWeight: '600',
  cursor: 'pointer',
  marginTop: '50px',
};

const dropDownStyle = {
  borderRadius: '5px',
  height: '50px',
  border: `2px solid ${theme.colors.secondary.lighter}`,
  backgroundColor: theme.colors.miscellaneous.white,
};

const CreateServiceModalContent = () => {
  const { serviceTypes, serviceCategories } = useSelector((state) => ({
    serviceTypes: state?.serviceTypes,
    serviceCategories: state?.serviceCategories,
  }));

  const [submitLoading, setSubmitLoading] = useState(false);
  const [typesOptions, setTypeOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [serviceName, setServiceName] = useState('');
  const [servicePrice, setServicePrice] = useState('');
  const [serviceDescription, setServiceDescription] = useState('');

  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    if (isEmpty(selectedType) || isEmpty(selectedCategory)) {
      errorLogger({ message: 'Service Type or Service Category Is Not Selected' }, addToast, () => {
        setSubmitLoading(false);
      });
    } else {
      try {
        const body = {
          description: serviceDescription,
          title: serviceName,
          service_price: servicePrice,
          service_type: selectedType[0]?._id,
          category: selectedCategory[0]?._id,
        };
        await apiInstance.post('/baseService/create', body);
        addToast('Added Service Successfully', successToastOptions);
        setSubmitLoading(false);
        navigate(servicesRoute);
        dispatch(
          fetchServicesAsyncThunk({
            successCallback: () => {},
            errorCallback: (error) => {
              errorLogger(error, addToast);
            },
            value: { config: {} },
          })
        );
        dispatch(closeCreateServiceModal());
      } catch (error) {
        errorLogger(error, addToast, () => {
          setSubmitLoading(false);
        });
      }
    }
  };

  useEffect(() => {
    if (!serviceTypes.isLoading && !serviceCategories.isLoading) {
      const types = convertHashMapToArray(serviceTypes.value);
      setTypeOptions(types);
      setSelectedType(!isEmpty(types) ? [types[0]] : []);
    }
  }, []);

  useEffect(() => {
    const categories = convertHashMapToArray(serviceCategories.value);
    const filteredCategories = categories.filter((category) => category?.type_id === selectedType[0]?._id);
    setCategoryOptions(filteredCategories);
    setSelectedCategory(!isEmpty(filteredCategories) ? [filteredCategories[0]] : []);
  }, [selectedType]);

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={4}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Lable>Service Type</Lable>
                <CustomDropDown
                  options={typesOptions}
                  values={selectedType}
                  onChange={(e) => {
                    setSelectedType(e);
                  }}
                  valueField={_ID}
                  labelField={TYPE}
                  style={dropDownStyle}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Lable>Service Category</Lable>
                <CustomDropDown
                  options={categoryOptions}
                  values={selectedCategory}
                  onChange={(e) => {
                    setSelectedCategory(e);
                  }}
                  valueField={_ID}
                  labelField={CATEGORY}
                  style={dropDownStyle}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={4}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Lable>Service Name *</Lable>
                <CustomInput
                  name="Service Name"
                  required
                  value={serviceName}
                  onChange={(e) => {
                    setServiceName(e.target.value);
                  }}
                  style={dropDownStyle}
                  type="text"
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Lable>Service Price *</Lable>
                <CustomInput
                  name="Service Price"
                  required
                  value={servicePrice}
                  onChange={(e) => {
                    setServicePrice(e.target.value);
                  }}
                  style={dropDownStyle}
                  type="number"
                  className="number-input"
                  onWheel={(e) => e.target.blur()}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Lable>Description</Lable>
            <TextBox
              style={{
                backgroundColor: theme.colors.miscellaneous.white,
              }}
              rows={6}
              value={serviceDescription}
              onChange={(e) => setServiceDescription(e.target.value)}
            />
          </Grid>
        </Grid>
        <CustomButton type="submit" customStyle={buttonStyle} loading={submitLoading}>
          Create
        </CustomButton>
      </Form>
    </Container>
  );
};

export default CreateServiceModalContent;
