import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { convertArrayToHashMap } from '../helpers/component';
import { typesInitialState } from './states';

const initialState = typesInitialState;

export const fetchTypesAsync = createAsyncThunk('types/fetchAsync', async (input, thunkApi) => {
  const { successCallback, errorCallback } = input;
  const { getState, rejectWithValue } = thunkApi;
  const { user } = getState();
  const config = {
    headers: {
      authorization: `Bearer ${user.accessToken}`,
    },
  };
  try {
    const types = await axios.get(`${process.env.REACT_APP_API_END_POINT}types`, config);
    successCallback();
    return convertArrayToHashMap(types.data.content, '_id');
  } catch (error) {
    errorCallback(error);
    return rejectWithValue(error.response.data);
  }
});

const typesSlice = createSlice({
  name: 'types',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTypesAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTypesAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.value = action.payload;
    });
    builder.addCase(fetchTypesAsync.rejected, (state) => {
      state.isLoading = true;
    });
  },
});

export default typesSlice.reducer;
