import styled from 'styled-components';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { TablePagination } from '@mui/material';

import theme from '../../utils/theme';
import {
  StyledHeadTableCell,
  StyledTable,
  StyledTableBody,
  StyledTableContainer,
  StyledTableHead,
  StyledTableHeadRow,
} from '../../utils/styledComponents';
import GarageBookingItem from './GarageBookingItem';

const Container = styled.div``;

const headTableCellStyle = {
  padding: '10px',
  border: 'none',
  color: theme.colors.secondary.light,
  fontSize: theme.fontSizes['text-sm'],
  fontFamily: theme.fontFamilies.lato,
  fontWeight: '400',
};

const GarageBookings = (props) => {
  const { garageBookings } = props;
  const [page, setpage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setpage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setpage(0);
  };
  return (
    <Container>
      <StyledTableContainer>
        <StyledTable sx={{ borderCollapse: 'separate', borderSpacing: '0 0.3rem' }}>
          <StyledTableHead>
            <StyledTableHeadRow>
              <StyledHeadTableCell sx={{ ...headTableCellStyle }}>Booking Ref</StyledHeadTableCell>
              <StyledHeadTableCell sx={headTableCellStyle}>Appointment Date</StyledHeadTableCell>
              <StyledHeadTableCell sx={headTableCellStyle}>Car Name</StyledHeadTableCell>
              <StyledHeadTableCell sx={headTableCellStyle}>VIN</StyledHeadTableCell>
              <StyledHeadTableCell sx={headTableCellStyle}>Customer Name</StyledHeadTableCell>
              <StyledHeadTableCell sx={headTableCellStyle}>Booking Through</StyledHeadTableCell>
              <StyledHeadTableCell sx={headTableCellStyle}>Total Amount</StyledHeadTableCell>
            </StyledTableHeadRow>
          </StyledTableHead>
          <StyledTableBody>
            {garageBookings?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((booking, index) => (
              <GarageBookingItem booking={booking} key={index} />
            ))}
          </StyledTableBody>
        </StyledTable>
      </StyledTableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={garageBookings.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
};

GarageBookings.propTypes = {
  garageBookings: PropTypes.array,
};

export default GarageBookings;
