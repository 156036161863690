import styled from 'styled-components';

import Page from '../components/Page';
import variants from '../utils/variants';
import EditManager from '../components/EditManager';

const Container = styled.div`
  width: 100%;
`;

const Editmanager = () => (
  <Page variant={variants.general} header="Update Manager" title="Update Manager">
    <Container>
      <EditManager />
    </Container>
  </Page>
);

export default Editmanager;
