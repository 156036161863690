import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { closeNavBar } from '../../utils/redux/helpersSlice';

const VisisbleBackground = styled.aside`
  z-index: 97;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 1;
  background-color: #00000080;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const NavSidebarOpenBackground = () => {
  const navbarVisibility = useSelector((state) => state?.helpers?.navbarVisibility);
  const dispatch = useDispatch();

  const handleCloseNavBar = (e) => {
    e.stopPropagation();
    dispatch(closeNavBar());
  };

  return navbarVisibility && <VisisbleBackground onClick={handleCloseNavBar} />;
};

export default NavSidebarOpenBackground;
