import styled from 'styled-components';
import PropTypes from 'prop-types';
import { TablePagination } from '@mui/material';
import { useState } from 'react';

import {
  StyledHeadTableCell,
  StyledTable,
  StyledTableBody,
  StyledTableContainer,
  StyledTableHead,
  StyledTableHeadRow,
} from '../../utils/styledComponents';
import theme from '../../utils/theme';
import PromoCodeItem from './PromoCodeItem';

const Container = styled.div`
  margin-top: 15px;
  padding: 0 15px;
  border: ${(props) => `1px solid ${props.theme.colors.secondary.lighter}`};
  border-radius: 15px;
`;

const headTableCellStyle = {
  padding: '10px',
  border: 'none',
  color: theme.colors.secondary.light,
  fontSize: theme.fontSizes['text-sm'],
  fontFamily: theme.fontFamilies.lato,
  fontWeight: '400',
};

const PromocodesList = (props) => {
  const { promocodes } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container>
      <StyledTableContainer>
        <StyledTable
          sx={{ borderCollapse: 'separate', borderSpacing: '0 0.6rem', width: '100%' }}
          aria-label="customized table"
        >
          <StyledTableHead>
            <StyledTableHeadRow>
              <StyledHeadTableCell sx={headTableCellStyle}>S/N</StyledHeadTableCell>
              <StyledHeadTableCell sx={headTableCellStyle}>Code</StyledHeadTableCell>
              <StyledHeadTableCell sx={headTableCellStyle}>Start Time</StyledHeadTableCell>
              <StyledHeadTableCell sx={headTableCellStyle}>End Time</StyledHeadTableCell>
              <StyledHeadTableCell sx={headTableCellStyle}>Created On</StyledHeadTableCell>
              <StyledHeadTableCell sx={headTableCellStyle}>Garage Name</StyledHeadTableCell>
              <StyledHeadTableCell sx={headTableCellStyle} align="center">
                Status
              </StyledHeadTableCell>
              <StyledHeadTableCell sx={headTableCellStyle} align="center">
                Edit
              </StyledHeadTableCell>
            </StyledTableHeadRow>
          </StyledTableHead>
          <StyledTableBody>
            {promocodes?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((promoCode, index) => (
              <PromoCodeItem promoCode={promoCode} key={index} index={index} page={page} rowsPerPage={rowsPerPage} />
            ))}
          </StyledTableBody>
        </StyledTable>
        <TablePagination
          rowsPerPageOptions={[10, 15, 20]}
          component="div"
          count={promocodes.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </StyledTableContainer>
    </Container>
  );
};

PromocodesList.propTypes = {
  promocodes: PropTypes.array,
};

export default PromocodesList;
