import styled from 'styled-components';
import PropTypes from 'prop-types';
import { TablePagination } from '@mui/material';
import { useState } from 'react';

import { isEmpty } from 'lodash';
import {
  StyledHeadTableCell,
  StyledTable,
  StyledTableBody,
  StyledTableContainer,
  StyledTableHead,
  StyledTableHeadRow,
} from '../../utils/styledComponents';
import theme from '../../utils/theme';
import CustomerItem from './CustomerItem';
import EmptyMessage from '../EmptyMessage';

const Container = styled.div`
  margin-top: 15px;
  padding: 0 15px;
  border: ${(props) => `1px solid ${props.theme.colors.secondary.lighter}`};
  border-radius: 15px;
`;

const headTableCellStyle = {
  padding: '10px',
  border: 'none',
  color: theme.colors.secondary.light,
  fontSize: theme.fontSizes['text-sm'],
  fontFamily: theme.fontFamilies.lato,
  fontWeight: '400',
};

const EmptyContainerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const CustomersList = (props) => {
  const { customers } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isEmpty(customers) ? (
        <EmptyMessage messageHeading="No Customers Found" containerStyle={EmptyContainerStyle} />
      ) : (
        <Container>
          <StyledTableContainer>
            <StyledTable
              sx={{ borderCollapse: 'separate', borderSpacing: '0 0.6rem', width: '100%' }}
              aria-label="customized table"
            >
              <StyledTableHead>
                <StyledTableHeadRow>
                  <StyledHeadTableCell sx={headTableCellStyle} align="left">
                    S/N
                  </StyledHeadTableCell>
                  <StyledHeadTableCell sx={headTableCellStyle} align="left">
                    Name
                  </StyledHeadTableCell>
                  <StyledHeadTableCell sx={headTableCellStyle} align="left">
                    Mobile Number
                  </StyledHeadTableCell>
                  <StyledHeadTableCell sx={headTableCellStyle} align="left">
                    Email
                  </StyledHeadTableCell>
                  <StyledHeadTableCell sx={headTableCellStyle} align="left">
                    No of Vehicles
                  </StyledHeadTableCell>
                  <StyledHeadTableCell sx={headTableCellStyle} align="left">
                    No of Bookings
                  </StyledHeadTableCell>
                </StyledTableHeadRow>
              </StyledTableHead>
              <StyledTableBody>
                {customers &&
                  customers
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((item, index) => (
                      <CustomerItem key={index} item={item} page={page} rowsPerPage={rowsPerPage} index={index} />
                    ))}
              </StyledTableBody>
            </StyledTable>
            <TablePagination
              rowsPerPageOptions={[10, 15, 20]}
              component="div"
              count={customers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </StyledTableContainer>
        </Container>
      )}
    </>
  );
};

CustomersList.propTypes = {
  customers: PropTypes.array,
};

export default CustomersList;
