import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { removeUserPersistace } from '../persistance';
import { userInitialState } from './states';

const initialState = userInitialState;

export const fetchUserAsync = createAsyncThunk('user/fetchUser', async () => {});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.isLoggedIn = true;
      state.accessToken = action.payload.accessToken;
      state.userDetails = action.payload.userDetails;
      state.refreshToken = action.payload.refreshToken;
    },
    signOut: (state) => {
      removeUserPersistace();
      state.isLoggedIn = false;
      state.accessToken = null;
      state.userDetails = null;
      state.refreshToken = null;
    },
  },
});

export const { signIn, signOut } = userSlice.actions;

export default userSlice.reducer;
