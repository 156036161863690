import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import theme from '../../utils/theme';
import variants from '../../utils/variants';
import CustomButton from '../Adapters/CustomButton';

import { openCreateServiceModal } from '../../utils/redux/modalSlice';

const Container = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  height: 100px;
  width: 100%;
  padding: 35px 20px 0 145px;
  background-color: ${(props) => props.theme.backgroundColors.blueCyan1};
  z-index: 95;
  @media screen and (max-width: 1200px) {
    padding: 35px 20px 0 120px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Span = styled.span``;

const Heading = styled.h2`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-4xl']};
  line-height: ${(props) => props.theme.lineHeights['text-4xl']};
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

const buttonStyle = {
  backgroundColor: theme.colors.primary.main,
  border: 'none',
  boxShadow:
    '0px 109.322px 87.4576px rgba(41, 72, 152, 0.05), 0px 70.8569px 51.2194px rgba(41, 72, 152, 0.037963), 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 21.8644px 14.2119px rgba(41, 72, 152, 0.025), 0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296), 0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037)',
  padding: '10px 30px',
  borderRadius: '8px',
  color: theme.colors.miscellaneous.white,
  fontWeight: '600',
  cursor: 'pointer',
};

const ServicesHeader = () => {
  const dispatch = useDispatch();

  const handleNavigateToAddServices = () => {
    dispatch(openCreateServiceModal());
  };

  return (
    <Container>
      <Wrapper>
        <Heading>Services</Heading>
        <CustomButton variant={variants.primary} customStyle={buttonStyle} onClick={handleNavigateToAddServices}>
          <Span style={{ marginRight: '10px' }}>
            <i className="fa-solid fa-plus" />
          </Span>
          Create Service
        </CustomButton>
      </Wrapper>
    </Container>
  );
};

export default ServicesHeader;
