import styled from 'styled-components';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import SalesGraph from './SalesGraph';
import GarageBookings from './GarageBookings';

const Container = styled.div`
  margin: 20px 0px 0px 0px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
`;

const LeftContainer = styled.div`
  width: 420px;
`;

const Heading = styled.h1`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-xl']};
  font-weight: 700;
  padding-left: 20px;
`;

const StatusBox = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  background-color: ${(props) => props.theme.backgroundColors.lotion};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #f1f2f2;
  padding-bottom: 10px;
`;

const Text = styled.p`
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  font-weight: 400;
`;

const Num = styled.h1`
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-lg']};
  color: ${(props) => props.theme.colors.primary.main};
`;

const RightContainer = styled.div`
  width: 100%;
  ${Heading} {
    padding-left: 35px;
  }
`;

const BookingsTableContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.backgroundColors.lotion};
  border-radius: 8px;
  height: 560px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px 10px 0px 10px;
`;

const GarageStats = (props) => {
  const { salesGraphData, garageBookings, garageStats } = props;
  const [garageBookingsList, setGarageBookingsList] = useState([]);

  const appBookings = garageBookings.filter((booking) => booking.booking_through === 'app');

  const gmsBookings = garageBookings.filter((booking) => booking.booking_through === 'gms');

  const webBookings = garageBookings.filter((booking) => booking.booking_through === 'qrlink');

  useEffect(() => {
    setGarageBookingsList(garageBookings);
  }, [garageBookings]);

  return (
    <Container>
      <LeftContainer>
        <Heading>Garage Status</Heading>
        <StatusBox>
          <Wrapper>
            <Text>No of Bookings at Garage</Text>
            <Num>{gmsBookings?.length}</Num>
          </Wrapper>
          <Wrapper>
            <Text>No of Bookings through App</Text>
            <Num>{appBookings?.length}</Num>
          </Wrapper>
          <Wrapper>
            <Text>No of Bookings through Web</Text>
            <Num>{webBookings?.length}</Num>
          </Wrapper>
          <Wrapper>
            <Text>Total Revenue</Text>
            <Num>$ {garageStats?.total_revenue?.toFixed(2)}</Num>
          </Wrapper>
        </StatusBox>
        <SalesGraph salesGraphData={salesGraphData} />
      </LeftContainer>
      <RightContainer>
        <Heading>Bookings</Heading>
        <BookingsTableContainer>
          <GarageBookings garageBookings={garageBookingsList} />
        </BookingsTableContainer>
      </RightContainer>
    </Container>
  );
};

GarageStats.propTypes = {
  salesGraphData: PropTypes.object,
  garageBookings: PropTypes.array,
  garageStats: PropTypes.object,
};

export default GarageStats;
