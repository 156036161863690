import axios from 'axios';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');
const { customersInitialState } = require('./states');

const initialState = customersInitialState;

export const fetchCustomersAsync = createAsyncThunk('customers/fetchAsync', async (input, thunkApi) => {
  const { successCallback, errorCallback } = input;
  const { getState, rejectWithValue } = thunkApi;
  const { user } = getState();
  const config = {
    headers: {
      authorization: `Bearer ${user.accessToken}`,
    },
  };
  try {
    const customers = await axios.get(`${process.env.REACT_APP_API_END_POINT}users/admin/getAllUsers`, config);
    successCallback();
    return customers.data.content;
  } catch (error) {
    errorCallback(error);
    return rejectWithValue(error.response.data);
  }
});

const customerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCustomersAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCustomersAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.value = action.payload;
    });
    builder.addCase(fetchCustomersAsync.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default customerSlice.reducer;
