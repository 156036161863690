import { pick } from 'lodash';

import { REDUX_PERSIST } from '../localization';
import { combinedInitialState, stateToBePersisted } from '../redux/states';

export const loadState = () => {
  let persistedState = combinedInitialState;
  const serializedState = JSON.parse(window.localStorage.getItem(REDUX_PERSIST));
  if (!serializedState) {
    persistedState = combinedInitialState;
    console.warn('No Local Storage Present');
  } else {
    try {
      persistedState = serializedState;
    } catch (error) {
      console.warn('Error In Loading Local Storage');
    }
  }
  return pick(persistedState, stateToBePersisted);
};

export const saveState = (state) => {
  const serializedState = pick(state, stateToBePersisted);
  window.localStorage.setItem(REDUX_PERSIST, JSON.stringify(serializedState));
};
