import moment from "moment";

export const getWeekendDays = (startDate, endDate) => {
  const weekend = [];
  let currentDateObject = startDate;
  const endDateObject = endDate;
  while (new Date(currentDateObject) <= new Date(endDateObject)) {
    if (Number(new Date(currentDateObject).getDay()) === 0 || Number(new Date(currentDateObject).getDay()) === 6) {
      weekend.push({
        year: new Date(currentDateObject).getFullYear(),
        month: Number(new Date(currentDateObject).getMonth()) + 1,
        day: new Date(currentDateObject).getDate(),
        className: 'disabledWeekedClass',
      });
    }
    currentDateObject = new Date(currentDateObject).setDate(new Date(currentDateObject).getDate() + 1);
  }

  return weekend;
};

const className = ['bookingFullClass', 'bookingEmptyClass'];

export const getWeekDays = (startDate, endDate) => {
  const weekend = [];
  let currentDateObject = startDate;
  const endDateObject = endDate;
  while (new Date(currentDateObject) <= new Date(endDateObject)) {
    if (Number(new Date(currentDateObject).getDay()) !== 0 || Number(new Date(currentDateObject).getDay()) !== 6) {
      weekend.push({
        year: new Date(currentDateObject).getFullYear(),
        month: Number(new Date(currentDateObject).getMonth()) + 1,
        day: new Date(currentDateObject).getDate(),
        className: className[parseInt(Math.random() * 2, 10)],
      });
    }
    currentDateObject = new Date(currentDateObject).setDate(new Date(currentDateObject).getDate() + 1);
  }

  return weekend;
};

export const decodeDate = (date) => {
  const getYear = moment(date).format('YYYY');
  const getMonth = moment(date).format('MMMM');
  const getDay = moment(date).format('DD');
  return getYear && getMonth && getDay ? `${getMonth} ${getDay}, ${getYear}` : date
}