import { Grid, Switch, TextField, ThemeProvider } from '@mui/material';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import CustomInput from '../Adapters/CustomInput';
import variants from '../../utils/variants';
import CustomButton from '../Adapters/CustomButton';
import { AMOUNT, PERCENTAGE, SUBMIT } from '../../utils/localization';
import theme from '../../utils/theme';
import { timePickerTheme } from '../../utils/mui/createThemes';
import { convertHashMapToArray, errorLogger, formatCreatePromoCodeBodyData } from '../../utils/helpers/component';
import apiInstance from '../../utils/apiInstance';
import { errorToastOptions } from '../../utils/constants';
import { fetchPromocodesAsync } from '../../utils/redux/promocodesSlice';
import Page from '../Page';
import CustomDropDown from '../Adapters/CustomDropDown';

const Container = styled.form`
  width: 100%;
  margin: 0px auto;
`;

const Heading = styled.h3`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-xl']};
  font-weight: 400;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

const Label = styled.label`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  font-weight: 400;
  margin-bottom: 10px;
`;

const Text = styled.p`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  font-weight: 400;
`;

const buttonStyle = {
  backgroundColor: theme.colors.primary.main,
  border: 'none',
  boxShadow:
    '0px 109.322px 87.4576px rgba(41, 72, 152, 0.05), 0px 70.8569px 51.2194px rgba(41, 72, 152, 0.037963), 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 21.8644px 14.2119px rgba(41, 72, 152, 0.025), 0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296), 0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037)',
  padding: '15px 45px',
  borderRadius: '8px',
  color: theme.colors.miscellaneous.white,
  fontWeight: '600',
  cursor: 'pointer',
  margin: '50px 0',
};

const inputStyle = {
  height: '50px',
  fontSize: theme.fontSizes['text-base'],
  font: theme.fontFamilies.lato,
  borderRadius: '5px',
  backgroundColor: theme.colors.miscellaneous.white,
  marginTop: '0',
  border: `1px solid ${theme.colors.miscellaneous.greyLightest}`,
};

const backNavButtonStyle = {
  color: theme.colors.secondary.main,
  fontSize: '1rem',
  cursor: 'pointer',
  margin: 'auto',
  lineHeight: 'unset',
  verticalAlign: 'unset',
};

const CreatePromocode = () => {
  const garages = useSelector((state) => state?.garages);
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [coupon, setCoupon] = useState({
    code: '',
    description: '',
    validFrom: dayjs().set('hour', 0).set('minute', 0).set('second', 0).toISOString(),
    validTill: dayjs().set('hour', 23).set('minute', 59).set('second', 59).toISOString(),
    type: AMOUNT,
    amount: '',
    percentage: '',
    minimumCartValue: '',
    maximumDiscountValue: '',
    maximumTotalUseCount: '',
    garageId: '',
  });

  const [loading, setLoading] = useState({
    submitButtonLoading: false,
  });

  const updateFieldData = ({ fieldLabel, fieldValue }) => {
    setCoupon((state) => ({ ...state, [fieldLabel]: fieldValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading((state) => ({ ...state, submitButtonLoading: true }));
    try {
      const check1 = dayjs(coupon?.validFrom).isBefore(coupon?.validTill);
      const check2 = coupon?.minimumCartValue > coupon?.maximumDiscountValue;
      if (check1 && check2) {
        const body = formatCreatePromoCodeBodyData({
          ...coupon,
          validFrom: dayjs(coupon?.validFrom).set('hour', 0).set('minute', 0).set('second', 0).toISOString(),
          validTill: dayjs(coupon?.validTill).set('hour', 24).set('minute', 59).set('second', 59).toISOString(),
        });
        await apiInstance.post(`promocode/admin/create?garage_id=${body.garage_id}`, body);
        dispatch(
          fetchPromocodesAsync({
            successCallback: () => {},
            errorCallback: (error) => {
              errorLogger(error, addToast);
            },
            value: {},
          })
        );
        handleCloseCreateSlot();
      } else if (!check1) {
        addToast('Invalid Date Range', errorToastOptions);
      } else {
        addToast('Minimum cart value has to more than maximum discount amount', errorToastOptions);
      }
    } catch (error) {
      console.log(error);
      errorLogger(error, addToast, () => {});
    } finally {
      setLoading((state) => ({ ...state, submitButtonLoading: false }));
    }
  };

  const handleCloseCreateSlot = () => {
    navigate(`/promo-codes`);
  };

  return (
    <Page variant={variants.general} header="Create Promocode" title="Create Promocode">
      <Container onSubmit={handleSubmit}>
        <Heading>
          <Link to={`/promo-codes`}>
            <i className="fa-sharp fa-solid fa-arrow-left fa-lg" style={backNavButtonStyle} />
          </Link>
          Create Promo Code
        </Heading>
        <ThemeProvider theme={timePickerTheme}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Label>Coupon Code*</Label>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <CustomInput
                    type="text"
                    required
                    style={{ ...inputStyle, textTransform: 'uppercase' }}
                    name="Coupon Code"
                    placeHolder="Coupon Code"
                    onChange={(e) => {
                      if (e.target.value.includes(' ')) {
                        e.target.value = e.target.value.replace(/\s/g, '');
                      }
                      updateFieldData({ fieldLabel: 'code', fieldValue: e.target.value });
                    }}
                    value={coupon?.code}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Label>Garage*</Label>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <CustomDropDown
                    options={convertHashMapToArray(garages.value)}
                    name="garage"
                    required
                    labelField="name"
                    valueField="_id"
                    onChange={(e) => {
                      updateFieldData({ fieldLabel: 'garageId', fieldValue: e[0]._id });
                    }}
                    style={{
                      backgroundColor: theme.backgroundColors.white,
                      height: '50px',
                      fontSize: theme.fontSizes['text-base'],
                      font: theme.fontFamilies.gotham,
                      borderRadius: '5px',
                      marginTop: '0',
                      border: `1px solid ${theme.colors.miscellaneous.greyLightest}`,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Label>Description</Label>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    type="text"
                    name="Description"
                    multiline
                    rows={3}
                    onChange={(e) => {
                      updateFieldData({ fieldLabel: 'description', fieldValue: e.target.value });
                    }}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${theme.colors.miscellaneous.greyLightest}`,
                        backgroundColor: '#fff',
                        zIndex: -1,
                      },
                    }}
                    fullWidth
                    inputProps={{
                      style: {
                        fontSize: theme.fontSizes['text-base'],
                        font: theme.fontFamilies.lato,
                        backgroundColor: '#fff',
                        color: '#000',
                      },
                      placeholder: 'Description',
                    }}
                    value={coupon?.description}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Label>Valid From*</Label>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      slotProps={{
                        textField: {
                          inputProps: {
                            sx: {
                              height: '50px',
                              fontSize: theme.fontSizes['text-base'],
                              font: theme.fontFamilies.lato,
                              paddingTop: 0,
                              paddingBottom: 0,
                            },
                            required: true,
                          },
                          sx: {
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: `1px solid ${theme.colors.miscellaneous.white}`,
                            },
                            '.MuiInputBase-root.MuiOutlinedInput-root': {
                              backgroundColor: theme.colors.miscellaneous.white,
                            },
                            '.MuiInputBase-root.MuiOutlinedInput-root:hover': {
                              border: `1px solid ${theme.colors.miscellaneous.white}`,
                            },
                          },
                          fullWidth: true,
                          required: true,
                          helperText: '* Send At must be between 1200 seconds and 7 days in the future',
                        },
                      }}
                      onChange={(e) =>
                        updateFieldData({
                          fieldLabel: 'validFrom',
                          fieldValue: e,
                        })
                      }
                      value={dayjs(coupon?.validFrom)}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Label>Valid Until*</Label>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      slotProps={{
                        textField: {
                          inputProps: {
                            sx: {
                              height: '50px',
                              fontSize: theme.fontSizes['text-base'],
                              font: theme.fontFamilies.lato,
                              paddingTop: 0,
                              paddingBottom: 0,
                            },
                            required: true,
                          },
                          sx: {
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: `1px solid ${theme.colors.miscellaneous.white}`,
                            },
                            '.MuiInputBase-root.MuiOutlinedInput-root': {
                              backgroundColor: theme.colors.miscellaneous.white,
                            },
                            '.MuiInputBase-root.MuiOutlinedInput-root:hover': {
                              border: `1px solid ${theme.colors.miscellaneous.white}`,
                            },
                          },
                          fullWidth: true,
                          required: true,
                          helperText: '* Send At must be between 1200 seconds and 7 days in the future',
                        },
                      }}
                      onChange={(e) => {
                        updateFieldData({ fieldLabel: 'validTill', fieldValue: e });
                      }}
                      value={dayjs(coupon?.validTill)}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Label>Discount {coupon.type === PERCENTAGE ? 'Percentage (%)' : 'Amount ($)'} *</Label>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <CustomInput
                    type="number"
                    className="number-input"
                    onWheel={(e) => e.target.blur()}
                    required={false}
                    style={inputStyle}
                    name="Discount"
                    placeHolder="Amount"
                    onChange={(e) => {
                      if (coupon.type === PERCENTAGE) {
                        return updateFieldData({ fieldLabel: 'percentage', fieldValue: parseInt(e.target.value, 10) });
                      }
                      updateFieldData({ fieldLabel: 'maximumDiscountValue', fieldValue: parseInt(e.target.value, 10) });
                      return updateFieldData({ fieldLabel: 'amount', fieldValue: parseInt(e.target.value, 10) });
                    }}
                    value={coupon?.type === PERCENTAGE ? coupon.percentage : coupon.amount}
                  />
                </Grid>
                <Text style={{ fontSize: theme.fontSizes['text-xs'], margin: '5px 0 0 auto' }}>
                  Toggle Percentage:&nbsp;
                  <Switch
                    size="small"
                    checked={coupon.type === PERCENTAGE}
                    onChange={(e) => {
                      if (e.target.checked) {
                        updateFieldData({ fieldLabel: 'amount', fieldValue: '' });
                        return updateFieldData({ fieldLabel: 'type', fieldValue: PERCENTAGE });
                      }
                      updateFieldData({ fieldLabel: 'percentage', fieldValue: '' });
                      return updateFieldData({ fieldLabel: 'type', fieldValue: AMOUNT });
                    }}
                  />
                </Text>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Label>Maximum Discount Amount ($)*</Label>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <CustomInput
                    type="number"
                    className="number-input"
                    onWheel={(e) => e.target.blur()}
                    required
                    style={inputStyle}
                    name="Maximum Discount Amount"
                    placeHolder="Maximum Discount Amount"
                    onChange={(e) => {
                      if (coupon.type === PERCENTAGE) {
                        return updateFieldData({
                          fieldLabel: 'maximumDiscountValue',
                          fieldValue: parseInt(e.target.value, 10),
                        });
                      }
                    }}
                    value={coupon?.maximumDiscountValue}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Label>Minimum Cart Value ($)*</Label>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <CustomInput
                    type="number"
                    className="number-input"
                    onWheel={(e) => e.target.blur()}
                    required
                    style={inputStyle}
                    name="Minimum Cart Value"
                    placeHolder="Minimum Cart Value"
                    onChange={(e) =>
                      updateFieldData({ fieldLabel: 'minimumCartValue', fieldValue: parseInt(e.target.value, 10) })
                    }
                    value={coupon?.minimumCartValue}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Label>Total Use Count</Label>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <CustomInput
                    type="number"
                    className="number-input"
                    onWheel={(e) => e.target.blur()}
                    required={false}
                    style={inputStyle}
                    name="Maximum Total UseCount"
                    onChange={(e) =>
                      updateFieldData({ fieldLabel: 'maximumTotalUseCount', fieldValue: parseInt(e.target.value, 10) })
                    }
                    placeHolder="Total Use Count"
                    value={coupon.maximumTotalUseCount}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
        <CustomButton
          variant={variants.primary}
          customStyle={buttonStyle}
          loading={loading.submitButtonLoading}
          type={SUBMIT}
        >
          Submit
        </CustomButton>
      </Container>
    </Page>
  );
};

export default CreatePromocode;
