import { Avatar } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { removeUserPersistace } from '../../utils/persistance';
import theme from '../../utils/theme';
import variants from '../../utils/variants';
import CustomButton from '../Adapters/CustomButton';

const Container = styled.div``;

const Wrapper = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
`;

const Label = styled.p`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  line-height: ${(props) => props.theme.fontSizes['text-base']};
  font-weight: 400;
`;

const Text = styled.p`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  line-height: ${(props) => props.theme.fontSizes['text-base']};
  font-weight: 600;
`;

const Box = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const buttonStyle = {
  border: `1px solid ${theme.colors.error.main}`,
  boxShadow:
    '0px 109.322px 87.4576px rgba(41, 72, 152, 0.05), 0px 70.8569px 51.2194px rgba(41, 72, 152, 0.037963), 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 21.8644px 14.2119px rgba(41, 72, 152, 0.025), 0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296), 0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037)',
  padding: '10px 30px',
  borderRadius: '8px',
  color: theme.colors.error.main,
  fontWeight: '600',
  cursor: 'pointer',
  marginTop: '25px',
  position: 'absolute',
  bottom: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
};

const UserProfileDrawerContent = () => {
  const user = useSelector((state) => state?.user);

  const handleLogout = () => {
    removeUserPersistace();
    window.location.reload();
  };

  return (
    <Container>
      <Wrapper>
        <Avatar style={{ width: '100px', height: '100px' }} />
        <Box>
          <Label>Name:&nbsp;&nbsp;&nbsp;</Label>
          <Text>{user?.userDetails?.name || user?.userDetails?.first_name + ' ' + user?.userDetails?.last_name}</Text>
        </Box>
        <Box>
          <Label>Email:&nbsp;&nbsp;&nbsp;</Label>
          <Text>{user?.userDetails?.email}</Text>
        </Box>
      </Wrapper>
      <CustomButton variant={variants.secondary} customStyle={buttonStyle} type="button" onClick={handleLogout}>
        Logout
      </CustomButton>
    </Container>
  );
};

export default UserProfileDrawerContent;
