import React from 'react';
import styled from 'styled-components';

import dashboardImage from '../../assets/images/svg/dashboard.svg';

const Container = styled.div`
  background-color: ${(props) => props.theme.backgroundColors.blueCyan1};
  height: 100%;
  width: 100%;
  padding: 50px 0;
  position: relative;
`;

const Wrapper = styled.div`
  max-width: 600px;
  padding: 0 20px;
`;

const Title = styled.h2`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-4xl']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-4xl']};
`;

const Paragraph = styled.div`
  color: ${(props) => props.theme.colors.secondary.light};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-lg']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-lg']};
  margin-top: 40px;
`;

const DashBoardImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 50%;
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const AuthIntro = () => (
  <Container>
    <Wrapper>
      <Title>
        Manage Your Services <br /> Easily with Mxpert CRM.
      </Title>
      <Paragraph>
        Automate and manage all your Services and internal work in one place to simplify your workflow with our Platform
      </Paragraph>
    </Wrapper>
    <DashBoardImage src={dashboardImage} alt="Dashboard Image" />
  </Container>
);

export default AuthIntro;
