import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import Page from '../components/Page';
import variants from '../utils/variants';
import Promotions from '../components/Promotions';
import { fetchPromotionsAsync } from '../utils/redux/promotionsSlice';
import { errorLogger } from '../utils/helpers/component';

const PromotionsPage = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  useEffect(() => {
    dispatch(
      fetchPromotionsAsync({
        successCallback: () => {},
        errorCallback: (error) => {
          errorLogger(error, addToast);
        },
        value: { config: {} },
      })
    );
  }, []);
  return (
    <Page variant={variants.general} header="Announcements" title="Promotions">
      <Promotions />
    </Page>
  );
};

export default PromotionsPage;
