import styled from 'styled-components';
import propTypes from 'prop-types';

import { TablePagination } from '@mui/material';
import { useState } from 'react';
import theme from '../../utils/theme';
import {
  StyledHeadTableCell,
  StyledTable,
  StyledTableBody,
  StyledTableContainer,
  StyledTableHead,
  StyledTableHeadRow,
} from '../../utils/styledComponents';
import Service from './Service';

const Container = styled.div`
  margin-top: 25px;
`;

const headTableCellStyle = {
  padding: '10px',
  border: 'none',
  color: theme.colors.secondary.light,
  fontSize: theme.fontSizes['text-sm'],
  fontFamily: theme.fontFamilies.lato,
  fontWeight: '400',
};

const Services = (props) => {
  const { services } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Container>
      <StyledTableContainer>
        <StyledTable sx={{ borderCollapse: 'separate', borderSpacing: '0 0.6rem' }}>
          <StyledTableHead>
            <StyledTableHeadRow>
              <StyledHeadTableCell sx={{ ...headTableCellStyle, width: '60px' }} align="left">
                S/N
              </StyledHeadTableCell>
              <StyledHeadTableCell sx={headTableCellStyle} align="left">
                Service Type
              </StyledHeadTableCell>
              <StyledHeadTableCell sx={headTableCellStyle} align="left">
                Category
              </StyledHeadTableCell>
              <StyledHeadTableCell sx={headTableCellStyle} align="left">
                Service Name
              </StyledHeadTableCell>
              <StyledHeadTableCell sx={headTableCellStyle} align="left">
                Service Price
              </StyledHeadTableCell>
              <StyledHeadTableCell sx={headTableCellStyle} align="center">
                Edit
              </StyledHeadTableCell>
              <StyledHeadTableCell sx={headTableCellStyle} align="center">
                Delete
              </StyledHeadTableCell>
            </StyledTableHeadRow>
          </StyledTableHead>
          <StyledTableBody>
            {services.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((service, index) => (
              <Service key={service._id} index={index} service={service} />
            ))}
          </StyledTableBody>
        </StyledTable>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={services.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </StyledTableContainer>
    </Container>
  );
};

Services.propTypes = {
  services: propTypes.array,
};

export default Services;
