import PropTypes from 'prop-types';
import theme from '../../utils/theme';
import { StyledBodyTableCell, StyledTableBodyRow } from '../../utils/styledComponents';

const bodyTableCellStyle = {
  color: theme.colors.secondary.darker,
  fontFamily: theme.fontFamilies.lato,
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: '500',
  border: 'none',
};

const bodyFirstTableCellStyle = {
  color: theme.colors.secondary.darker,
  fontFamily: theme.fontFamilies.lato,
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: '500',
  borderRadius: '8px 0 0 8px',
  border: 'none',
};

const GarageBookingItem = (props) => {
  const { booking } = props;
  return (
    <StyledTableBodyRow
      style={{
        backgroundColor: theme.colors.miscellaneous.white,
        borderRadius: '4px',
      }}
    >
      <StyledBodyTableCell sx={bodyFirstTableCellStyle}>{booking?.ref_no}</StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle}>
        {new Date(booking?.dropoff_date).toLocaleDateString()}
      </StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle}>{booking?.vehicle_id?.name}</StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle}>{booking?.vehicle_id?.vin}</StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle}>
        {booking?.user_id?.first_name + ' ' + booking?.user_id?.last_name}
      </StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle}>{booking?.booking_through.toUpperCase()}</StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle}>$ {booking?.total_price?.toFixed(2)}</StyledBodyTableCell>
    </StyledTableBodyRow>
  );
};

GarageBookingItem.propTypes = {
  booking: PropTypes.object,
};

export default GarageBookingItem;
