import PropTypes from 'prop-types';
import { Edit, Trash } from 'iconsax-react';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import theme from '../../utils/theme';
import { StyledBodyTableCell, StyledTableBodyRow } from '../../utils/styledComponents';
import CustomModal from '../Adapters/CustomModal';
import EditServiceModalContent from '../EditServiceModalContent.js';
import { errorLogger } from '../../utils/helpers/component';
import apiInstance from '../../utils/apiInstance';
import { fetchServicesAsyncThunk } from '../../utils/redux/servicesSlice';

const bodyTableCellStyle = {
  color: theme.colors.secondary.darker,
  fontFamily: theme.fontFamilies.lato,
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: '500',
  borderTop: `1px solid ${theme.colors.secondary.lighter}`,
  borderBottom: `1px solid ${theme.colors.secondary.lighter}`,
};

const bodyFirstTableCellStyle = {
  color: theme.colors.secondary.darker,
  fontFamily: theme.fontFamilies.lato,
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: '500',
  borderRadius: '8px 0 0 8px',
  borderTop: `1px solid ${theme.colors.secondary.lighter}`,
  borderLeft: `1px solid ${theme.colors.secondary.lighter}`,
  borderBottom: `1px solid ${theme.colors.secondary.lighter}`,
};

const bodyLastTableCellStyle = {
  fontFamily: theme.fontFamilies.lato,
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: '500',
  borderRadius: '0 8px 8px 0',
  borderTop: `1px solid ${theme.colors.secondary.lighter}`,
  borderRight: `1px solid ${theme.colors.secondary.lighter}`,
  borderBottom: `1px solid ${theme.colors.secondary.lighter}`,
};

const Service = (props) => {
  const { index, service } = props;
  const { addToast } = useToasts();
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  const { serviceTypes, serviceCategories } = useSelector((state) => ({
    serviceTypes: state.serviceTypes,
    serviceCategories: state.serviceCategories,
    user: state.user,
  }));

  const handleDelete = async () => {
    try {
      const deleteService = window.confirm('Do you really want to delete this service permanently?');
      if (deleteService === true) {
        await apiInstance.delete(`/baseService/delete/${service._id}`);
        dispatch(
          fetchServicesAsyncThunk({
            successCallback: () => {},
            errorCallback: (error) => {
              errorLogger(error, addToast);
            },
            value: { config: {} },
          })
        );
      }
    } catch (error) {
      errorLogger(error, addToast, () => {});
    }
  };

  return (
    <>
      <StyledTableBodyRow>
        <StyledBodyTableCell sx={bodyFirstTableCellStyle} align="left">
          {index + 1}
        </StyledBodyTableCell>
        <StyledBodyTableCell sx={bodyTableCellStyle} align="left">
          {service?.service_type?.type}
        </StyledBodyTableCell>
        <StyledBodyTableCell sx={bodyTableCellStyle} align="left">
          {service?.category?.category}
        </StyledBodyTableCell>
        <StyledBodyTableCell sx={bodyTableCellStyle} align="left">
          {service?.title}
        </StyledBodyTableCell>
        <StyledBodyTableCell sx={bodyTableCellStyle} align="left">
          {service?.service_price}
        </StyledBodyTableCell>
        <StyledBodyTableCell sx={bodyTableCellStyle} align="center">
          <Edit
            style={{ cursor: 'pointer' }}
            size="20"
            color={theme.colors.secondary.main}
            onClick={() => {
              setOpenModal(true);
            }}
          />
        </StyledBodyTableCell>
        <StyledBodyTableCell sx={bodyLastTableCellStyle} align="center">
          <Trash size="22" color={theme.colors.error.main} style={{ cursor: 'pointer' }} onClick={handleDelete} />
        </StyledBodyTableCell>
      </StyledTableBodyRow>
      <CustomModal
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
        containerStyle={{ minWidth: '600px' }}
        loading={serviceTypes.isLoading && serviceCategories.isLoading}
        heading="Update Service"
      >
        <EditServiceModalContent
          service={service}
          handleCloseModal={() => {
            setOpenModal(false);
          }}
        />
      </CustomModal>
    </>
  );
};

Service.propTypes = {
  service: PropTypes.object,
  index: PropTypes.number,
};

export default Service;
