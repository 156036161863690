import React, { useState } from 'react';
import styled from 'styled-components';

import mxpertLogo from '../../assets/images/png/mxpert-black-logo-full.png';

import { EMAIL, OTP } from '../../utils/localization';
import EmailWidget from './EmailWidget';
import OtpWidget from './OtpWidget';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.miscellaneous.white};
  display: grid;
  place-items: center;
`;

const Wrapper = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 50px 0;
`;

const BrandLogo = styled.img`
  width: auto;
  height: 60px;
  margin-bottom: 60px;
`;

const AuthDetails = () => {
  const [authWidget, setAuthWidget] = useState(EMAIL);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');

  const getAuthWidget = (variant) => {
    switch (variant) {
      case EMAIL:
        return <EmailWidget email={email} setEmail={setEmail} setAuthWidget={setAuthWidget} />;
      case OTP:
        return <OtpWidget email={email} otp={otp} setOtp={setOtp} setAuthWidget={setAuthWidget} />;
      default:
        break;
    }
  };

  return (
    <Container>
      <Wrapper>
        <BrandLogo src={mxpertLogo} alt="Mxpert Logo" />
        {getAuthWidget(authWidget)}
      </Wrapper>
    </Container>
  );
};
export default AuthDetails;
