import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import Zoom from 'react-medium-image-zoom';
import styled from 'styled-components';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';

import theme from '../../utils/theme';
import { StyledBodyTableCell, StyledTableBodyRow } from '../../utils/styledComponents';

const NavigationButton = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 17.5px;
  display: grid;
  place-items: center;
  border: 1px solid #e4e4e4;
  padding: 5px;
  cursor: pointer;
`;

const bodyTableCellStyle = {
  color: theme.colors.secondary.darker,
  fontFamily: theme.fontFamilies.lato,
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: '500',
  border: 'none',
};

const bodyFirstTableCellStyle = {
  color: theme.colors.secondary.darker,
  fontFamily: theme.fontFamilies.lato,
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: '500',
  borderRadius: '8px 0 0 8px',
  border: 'none',
};

const bodyLastTableCellStyle = {
  fontFamily: theme.fontFamilies.lato,
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: '500',
  color: theme.colors.error.main,
  borderRadius: '0 8px 8px 0',
};

const Garage = (props) => {
  const { garage, index } = props;
  const navigate = useNavigate();

  const navigateToGarage = () => {
    navigate(`/garage/${garage?._id}`);
  };

  return (
    <StyledTableBodyRow style={{ backgroundColor: theme.colors.miscellaneous.white }}>
      <StyledBodyTableCell sx={bodyFirstTableCellStyle} align="center">
        {index + 1}
      </StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle} align="center">
        <Zoom>
          <Avatar src={garage?.logo} style={{ width: '40px', height: '40px', margin: '0 auto' }} />
        </Zoom>
      </StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle}>{garage?.name}</StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle}>
        {garage?.address?.street} <br /> {garage?.address?.city}
      </StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle}>{garage?.contact_number}</StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle}>{garage?.email}</StyledBodyTableCell>
      <StyledBodyTableCell
        sx={{ ...bodyLastTableCellStyle, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <NavigationButton onClick={navigateToGarage}>
          <ArrowForwardIosIcon style={{ fontSize: theme.fontSizes['text-xl'] }} />
        </NavigationButton>
      </StyledBodyTableCell>
    </StyledTableBodyRow>
  );
};

Garage.propTypes = {
  garage: PropTypes.object,
  index: PropTypes.number,
};

export default Garage;
