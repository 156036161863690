import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-dropdown-select';
import theme from '../../../utils/theme';

const selectStyle = {
  fontSize: theme.fontSizes['text-sm'],
  fontFamily: theme.fontFamilies.lato,
  padding: '0 10px',
};

const CustomDropDown = (props) => {
  const { options, values, onChange, style, valueField, labelField } = props;
  return (
    <Select
      options={options}
      values={values}
      onChange={onChange}
      valueField={valueField}
      searchable={false}
      labelField={labelField}
      style={{ ...selectStyle, ...style }}
    />
  );
};

CustomDropDown.propTypes = {
  options: PropTypes.array,
  values: PropTypes.array,
  onChange: PropTypes.func,
  style: PropTypes.object,
  valueField: PropTypes.string,
  labelField: PropTypes.string,
};

CustomDropDown.defaultProps = {
  options: [],
  values: [],
  onChange: () => {},
  style: {},
  valueField: 'id',
  labelField: 'title',
};

export default CustomDropDown;
