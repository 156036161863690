import React from 'react';
import { useSelector } from 'react-redux';
import { Link, matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import mxpertLogoFull from '../../assets/images/png/mxpert-white-logo-full.png';
import { NAV_SIDE_BAR_CONFIG } from '../../utils/constants';

const Container = styled.div`
  height: 100%;
`;

const LogoContainer = styled(Link)`
  align-items: center;
  color: none;
  border: none;
  display: flex;
  height: 40px;
  justify-content: flex-start;
  text-decoration: none;
  width: ${(props) => (props.navbarVisibility ? '200px' : '40px')};
  overflow: hidden;
  transition: width 0.2s ease-in;
`;
const Logo = styled.img`
  width: auto;
  height: 55%;
  margin: ${(props) => (props.navbarVisibility ? ' 0 10px' : '0 2.5px')};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const NavItem = styled(Link)`
  align-items: center;
  color: none;
  background-color: ${(props) => (props.active ? '#fff' : props.theme.colors.secondary.main)};
  border: none;
  border-radius: 4px;
  display: flex;
  height: 40px;
  justify-content: ${(props) => (props.navbarVisibility ? 'flex-start' : 'center')};
  text-decoration: none;
  overflow: hidden;
  width: ${(props) => (props.navbarVisibility ? '200px' : '40px')};
  transition: width 0.2s ease-in;
  @media screen and (max-width: 1200px) {
    height: 30px;
    width: ${(props) => (props.navbarVisibility ? '200px' : '35px')};
  }
`;

const NavImage = styled.img`
  filter: ${(props) => (props.active ? 'none' : 'brightness(0) invert(1)')};
  height: 20px;
  width: 20px;
  margin: 10px;
  @media screen and (max-width: 1200px) {
    height: 18px;
    width: 18px;
    margin: 15px;
  }
`;

const NavTitle = styled.h3`
  color: ${(props) => (props.active ? props.theme.colors.primary.main : '#fff')};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  font-weight: 400;
  height: ${(props) => props.theme.lineHeights['text-base']};
  overflow: hidden;
  line-height: ${(props) => props.theme.lineHeights['text-base']};
  transition: width 0.2s ease-in;
  width: ${(props) => (props.navbarVisibility ? 'auto' : '0px')};
  transition: width 0.2s ease-in;
`;

const NavSidebarSection = () => {
  const location = useLocation();
  const navbarVisibility = useSelector((state) => state?.helpers?.navbarVisibility);

  const match = (path) =>
    !!matchPath(
      {
        path,
        exact: true,
        strict: true,
      },
      location.pathname
    );

  return (
    <Container>
      <Wrapper>
        <LogoContainer to="/" navbarVisibility={navbarVisibility}>
          <Logo navbarVisibility={navbarVisibility} src={mxpertLogoFull} alt="logo" loading="lazy" />
        </LogoContainer>
        {NAV_SIDE_BAR_CONFIG.map((navItem) => (
          <NavItem key={navItem.id} to={navItem.path} active={match(navItem.path)} navbarVisibility={navbarVisibility}>
            <NavImage src={navItem.icon} alt={navItem.title} active={match(navItem.path)} loading="lazy" />
            <NavTitle active={match(navItem.path)} navbarVisibility={navbarVisibility}>
              {navItem.title}
            </NavTitle>
          </NavItem>
        ))}
      </Wrapper>
    </Container>
  );
};

export default NavSidebarSection;
