const variants = {
  primary: 'primary',
  secondary: 'secondary',
  disabled: 'disabled',
  general: 'general',
  appointment: 'appointment',
  booking: 'booking',
  regular: 'regular',
  seasonal: 'seasonal',
  custom: 'custom',
  vehicle: 'vehicle',
  customer: 'customer',
  address: 'address',
  emptyChatWindow: 'emptyWindow',
  messagesChatWindow: 'messagesWindow',
  sendFilesChatWindow: 'sendFileWindow',
  success: 'success',
  error: 'error',
  warning: 'warning',
  garageOverview: 'garageOverview',
  calanderSettings: 'calanderSettings',
  services: 'services',
  ratingsAndreviews: 'ratingsAndreviews',
  garageProfile: 'garageProfile',
  staffManagement: 'staffManagement',
  messageTemplate: 'messageTemplate',
  promotionsAndPromoCodes: 'promotionsAndPromoCodes',
  dailyStatements: 'dailyStatements',
  addGarage: 'addGarage',
  garage: 'garage',
  addService: 'addService',
  editGarage: 'editGarage',
  editManager: 'editManager',
};

export default variants;
