import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import NavbarHeader from '../Navbar/NavbarHeader';
import variants from '../../utils/variants';
import AddGarageHeader from '../Navbar/AddGarageHeader';
import GarageHeader from '../Navbar/GarageHeader';
import ServicesHeader from '../Navbar/ServicesHeader';
import AddServiceHeader from '../Navbar/AddServiceHeader';
import LoadingComponent from '../Adapters/LoadingComponent';
import theme from '../../utils/theme';
import mxpertLogo from '../../assets/images/svg/mxpert-logo.svg';

const Container = styled.div``;

const Children = styled.div`
  padding-bottom: 20px;
`;

const LoadingWindow = styled.div`
  height: calc(100vh - 100px);
  display: grid;
  place-items: center;
`;

const Page = (props) => {
  const { children, header, title, variant, loading } = props;

  const getNavbarHeader = (variant, header = '') => {
    switch (variant) {
      case variants.general:
        return <NavbarHeader header={header} />;
      case variants.addGarage:
        return <AddGarageHeader />;
      case variants.garage:
        return <GarageHeader />;
      case variants.services:
        return <ServicesHeader />;
      case variants.addService:
        return <AddServiceHeader />;
      default:
        break;
    }
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="shortcut icon" href={mxpertLogo} type="image/x-icon" />
      </Helmet>
      <Container>
        {getNavbarHeader(variant, header)}
        {loading ? (
          <LoadingWindow>
            <LoadingComponent customStyle={{ fontSize: '3rem', color: theme.colors.primary.main }} />
          </LoadingWindow>
        ) : (
          <Children>{children}</Children>
        )}
      </Container>
    </>
  );
};

Page.propTypes = {
  children: PropTypes.element.isRequired,
  header: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  loading: PropTypes.bool,
};

Page.defaultProps = {
  header: '',
  title: 'Mxpert',
  variant: variants?.general,
  loading: false,
};

export default Page;
