import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { isEmpty, upperCase } from 'lodash';

import { FIREBASE_STORAGE } from '../../config/firebaseConfig';
import { errorToastOptions } from '../constants';
import { PERCENTAGE } from '../localization';

export const convertArrayToHashMap = (arrayInput = [], mapBy = 'id') => {
  const object = {};
  arrayInput.forEach((item) => {
    object[item[mapBy]] = item;
  });
  return object;
};

export const isUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

export const STREET_NAME_KEYS = ['street_number', 'route', 'sublocality_level_1', 'sublocality_level_2'];

export const formatStreetName = (address) => {
  const streetArray = [];
  STREET_NAME_KEYS.forEach((item) => {
    if (address[item]) {
      streetArray.push(address[item]);
    }
  });
  return streetArray.join(', ');
};

export const getFormattedAddress = (address) => {
  const addressArray = [];
  ['street', 'city', 'province', 'postalCode'].forEach((key) => {
    if (address[key]) {
      addressArray.push(address[key]);
    }
  });
  return addressArray.join(', ');
};

export const convertHashMapToArray = (objectInput) => {
  const array = Object.values(objectInput);
  return array;
};

export const disableBodyScroll = () => {
  const body = window.document.getElementsByTagName('body');
  body[0].style.overflowY = 'hidden';
};

export const enableBodyScroll = () => {
  const body = window.document.getElementsByTagName('body');
  body[0].style.overflowY = 'auto';
};

export const errorLogger = (error, addToast, callback = () => {}) => {
  if (error?.response?.data?.message) {
    addToast(`${error?.response?.data?.message}`, errorToastOptions);
  } else {
    addToast(`${error.message}`, errorToastOptions);
  }
  callback();
};

export const getCurrentMomentDateInputObject = () => {
  const date = new Date();
  return {
    year: date.getFullYear(),
    month: Number(date.getMonth()) + 1,
    day: date.getDate(),
  };
};

export const generateDateForBookingManagementArray = (date) => {
  let left = new Date(date).setDate(new Date(date).getDate() - 7);
  const right = new Date(date).setDate(new Date(date).getDate() + 7);
  const dateArray = [];
  while (new Date(left) <= new Date(right)) {
    dateArray.push({
      day: new Date(left).getDate(),
      month: new Date(left).getMonth() + 1,
      year: new Date(left).getFullYear(),
    });
    left = new Date(left).setDate(new Date(left).getDate() + 1);
  }
  return dateArray;
};

export const convertFirstLetterToCaps = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const getTypeById = ({ id, types }) => types.filter((category) => category?._id === id)[0];

export const uploadMediaToFireBase = async (file) => {
  const storageRef = ref(FIREBASE_STORAGE, uuidv4());
  const snapshot = await uploadBytes(storageRef, file);
  const url = await getDownloadURL(ref(FIREBASE_STORAGE, snapshot.ref.fullPath));
  return url;
};

export const formatCreatePromoCodeBodyData = (promoCodeDetails) => {
  const body = {
    garage_id: promoCodeDetails?.garageId,
    code: upperCase(promoCodeDetails.code).replace(/ +/g, ''),
    description: promoCodeDetails.description,
    start_date: promoCodeDetails?.validFrom,
    end_date: promoCodeDetails?.validTill,
    amount: promoCodeDetails.type === PERCENTAGE ? null : promoCodeDetails.amount,
    percentage: promoCodeDetails.type === PERCENTAGE ? promoCodeDetails.percentage : null,
    limit: promoCodeDetails.maximumTotalUseCount ? promoCodeDetails.maximumTotalUseCount : null,
    maximum_cap: promoCodeDetails?.maximumDiscountValue,
    minimum_cart_value: promoCodeDetails?.minimumCartValue,
    type: promoCodeDetails?.type,
    active: true,
  };
  return body;
};

export const formatSalesGraphData = ({ daily, weekly, monthly, fromDate }) => {
  const endDate = new Date(fromDate);
  let startDate = new Date(new Date(fromDate).setDate(new Date(fromDate).getDate() - 4));
  const dailyHashMap = convertArrayToHashMap(daily, '_id');
  const tempDaily = [];
  while (startDate <= endDate) {
    const formattedDate = moment(startDate).format('YYYY-MM-DD');
    if (dailyHashMap[formattedDate]) {
      tempDaily.push({ ...dailyHashMap[formattedDate], formattedDate });
    } else {
      tempDaily.push({
        formattedDate,
        revenue: 0,
      });
    }
    startDate = new Date(startDate).setDate(new Date(startDate).getDate() + 1);
  }
  const endWeek = moment().format('w');
  let startWeek = endWeek - 4;
  const weeklyHashMap = convertArrayToHashMap(weekly, '_id');
  const tempWeekly = [];
  while (startWeek <= endWeek) {
    if (weeklyHashMap[startWeek]) {
      tempWeekly.push({ ...weeklyHashMap[startWeek], formattedDate: `week ${startWeek}` });
    } else {
      tempWeekly.push({ formattedDate: `week ${startWeek}`, revenue: 0 });
    }
    startWeek += 1;
  }
  const endMonth = new Date(fromDate);
  const setMonth = new Date(fromDate).setMonth(new Date(fromDate).getMonth() - 4);
  const setDate = new Date(setMonth).setDate(1);
  let startMonth = new Date(setDate);
  const monthlyHashMap = convertArrayToHashMap(monthly, 'month_label');
  const tempMonthly = [];
  while (startMonth <= endMonth) {
    const formattedDate = moment(startMonth).format('YYYY-M');
    if (monthlyHashMap[formattedDate]) {
      tempMonthly.push({ ...monthlyHashMap[formattedDate], formattedDate });
    } else {
      tempMonthly.push({
        formattedDate,
        revenue: 0,
      });
    }
    startMonth = new Date(startMonth).setMonth(new Date(startMonth).getMonth() + 1);
  }
  return { daily: tempDaily, weekly: tempWeekly, monthly: tempMonthly };
};

export const filterCategoryBasedOnType = ({ categories, typeId }) => {
  if (isEmpty(categories) || !typeId) {
    return [];
  }
  return categories.filter((category) => category.type_id === typeId);
};

export const filterServicesBasedOnTypeAndCategory = ({ services, typeId, categoryId }) => {
  if (isEmpty(services) || !typeId || !categoryId) {
    return [];
  }
  return services.filter((service) => service?.service_type?._id === typeId && service?.category?._id === categoryId);
};
