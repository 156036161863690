export const userInitialState = {
  isLoggedIn: false,
  accessToken: null,
  userDetails: null,
  refreshToken: null,
};

export const helpersInitialState = {
  screenWidth: 0,
  navbarVisibility: false,
};

export const modalInitialState = {
  addTypeModal: false,
  addCategoryModal: false,
  userProfileModal: false,
  createServiceModal: false,
  addServiceModal: false,
};

export const bookingsInitialstate = {
  isLoading: true,
  value: {},
};

export const customersInitialState = {
  isLoading: true,
  value: {},
};

export const servicesInitialState = {
  isLoading: true,
  value: {},
};

export const garagesInitialState = {
  isLoading: true,
  value: {},
};

export const typesInitialState = {
  isLoading: true,
  value: {},
};

export const categoriesInitialState = {
  isLoading: true,
  value: {},
};

export const combinedInitialState = {
  user: userInitialState,
  helpers: helpersInitialState,
  modals: modalInitialState,
  bookings: bookingsInitialstate,
  services: servicesInitialState,
  garages: garagesInitialState,
  serviceTypes: typesInitialState,
  serviceCategories: categoriesInitialState,
};

export const promoCodesInitialState = {
  isLoading: true,
  value: {},
};

export const promotionsInitialState = {
  isLoading: true,
  value: {},
};

export const statsInitialState = {
  isLoading: true,
  value: {},
};

export const stateToBePersisted = ['user'];
