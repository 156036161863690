import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { convertArrayToHashMap } from '../helpers/component';
import { categoriesInitialState } from './states';

const initialState = categoriesInitialState;

export const fetchCategoriesAsync = createAsyncThunk('categories/fetchAsync', async (input, thunkApi) => {
  const { successCallback, errorCallback } = input;
  const { getState, rejectWithValue } = thunkApi;
  const { user } = getState();
  const config = {
    headers: {
      authorization: `Bearer ${user.accessToken}`,
    },
  };
  try {
    const categories = await axios.get(`${process.env.REACT_APP_API_END_POINT}categories`, config);
    successCallback();
    return convertArrayToHashMap(categories.data.content, '_id');
  } catch (error) {
    errorCallback(error);
    return rejectWithValue(error.response.data);
  }
});

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategoriesAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCategoriesAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.value = action.payload;
    });
    builder.addCase(fetchCategoriesAsync.rejected, (state) => {
      state.isLoading = true;
    });
  },
});

export default categoriesSlice.reducer;
