import { createTheme } from '@mui/material';
import theme from '../theme';

export const stepTheme = createTheme({
  palette: {
    primary: {
      lighter: '#C6C6C7',
      light: '#737476',
      main: '#101A20',
      dark: '#0E171D',
      darker: '#0A1014',
    },
  },
  components: {
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontFamily: theme.fontFamilies.lato,
          fontSize: theme.fontSizes['text-lg'],
          display: 'flex',
          justifyContent: 'space-between',
        },
        labelContainer: {
          marginLeft: '10px',
        },
      },
    },
  },
});

export const switchTheme = createTheme({
  palette: {
    primary: {
      main: '#fb5012',
    },
  },
  components: {
    MuiStepLabel: {
      styleOverrides: {},
    },
  },
});

export const tabsTheme = createTheme({
  palette: {
    primary: {
      main: '#fb5012',
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: theme.fontFamilies.lato,
          fontSize: theme.fontSizes['text-xs'],
          fontWeight: theme.fontWeights[500],
        },
      },
    },
  },
});

export const radioGroupTheme = createTheme({
  palette: {
    primary: {
      main: '#fb5012',
    },
  },
});

export const timePickerTheme = createTheme({
  palette: {
    primary: {
      lighter: '#FCACA1',
      light: '#FB8673',
      main: '#FB5012',
      dark: '#C23D0D',
      darker: '#702308',
    },
  },
  components: {},
});
