import { useEffect, useState } from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import SalesGraphHeader from './SalesGraphHeader';
import { DASHBOARD_SALES_GRAPH_FILTERS } from '../../utils/constants';
import { DAILY, MONTHLY, WEEKLY } from '../../utils/localization';
import SalesGraphContent from './SalesGraphContent';

const Container = styled.div`
  margin-top: 0px;
  @media screen and (max-width: 1200px) {
    margin-top: 0;
    width: 400px;
  }
`;

const SalesGraph = (props) => {
  const { salesGraphData } = props;
  const [salesGraphFilter, setSalesGraphFilter] = useState(DASHBOARD_SALES_GRAPH_FILTERS[0]);
  const [salesGraphFilteredData, setSalesGraphFilteredData] = useState(salesGraphData[DAILY]);

  useEffect(() => {
    switch (salesGraphFilter.value) {
      case DAILY:
        return setSalesGraphFilteredData(salesGraphData[DAILY]);
      case WEEKLY:
        return setSalesGraphFilteredData(salesGraphData[WEEKLY]);
      case MONTHLY:
        return setSalesGraphFilteredData(salesGraphData[MONTHLY]);
      default:
        break;
    }
  }, [salesGraphData, salesGraphFilter]);

  return (
    <Container>
      <SalesGraphHeader
        salesGraphFilter={salesGraphFilter}
        setSalesGraphFilter={setSalesGraphFilter}
        salesGraphData={salesGraphData}
      />
      <SalesGraphContent data={salesGraphFilteredData} />
    </Container>
  );
};

SalesGraph.propTypes = {
  salesGraphData: PropTypes.object,
};

export default SalesGraph;
