import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import { isEmpty } from 'lodash';
import Categories from '../components/Categories';
import Page from '../components/Page';
import Types from '../components/Types';
import variants from '../utils/variants';
import ServiceComponent from '../components/Services';
import ServicesFilter from '../components/ServicesFilter';
import { convertHashMapToArray, errorLogger } from '../utils/helpers/component';
import SearchWidget from '../components/SearchWidget';
import theme from '../utils/theme';
import EmptyMessage from '../components/EmptyMessage';
import LoadingComponent from '../components/Adapters/LoadingComponent';

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 25px;
`;

const Left = styled.div`
  width: 400px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.miscellaneous.white};
  padding: 20px;
  min-height: calc(100vh - 120px);
`;

const Right = styled.div`
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.miscellaneous.white};
  padding: 20px;
  position: relative;
`;

const Title = styled.h3`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-lg']};
  line-height: ${(props) => props.theme.lineHeights['text-lg']};
  font-weight: 400;
  margin-bottom: 15px;
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EmptyContainerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const LoadingWindow = styled.div`
  min-height: calc(100vh - 100px);
  width: 100%;
  flex: 1;
  display: grid;
  place-items: center;
  border-radius: 15px;
`;

const Services = () => {
  const { addToast } = useToasts();
  const servicesInfo = useSelector((state) => state?.services);
  const [searchFilteredServices, setSearchFilteredServices] = useState(convertHashMapToArray(servicesInfo?.value));
  const [loading, setLoading] = useState(true);

  const handleSearch = (searchKey) => {
    setLoading(true);
    try {
      const allServices = convertHashMapToArray(servicesInfo.value);
      const tempServices = allServices.filter((service) => {
        const serviceName = service?.title ?? '';
        const regex = RegExp(searchKey, 'i');
        return serviceName.search(regex) !== -1;
      });
      setSearchFilteredServices(tempServices);
    } catch (error) {
      errorLogger(error, addToast, () => {});
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      setLoading(true);
      setSearchFilteredServices(convertHashMapToArray(servicesInfo?.value));
    } catch (error) {
      errorLogger(error, addToast, () => {});
    } finally {
      setLoading(false);
    }
  }, [servicesInfo.value]);

  const serviceCategories = useSelector((state) => state?.serviceCategories);
  const serviceTypes = useSelector((state) => state?.serviceTypes);

  if (isEmpty(serviceCategories) && isEmpty(serviceTypes)) {
    return <EmptyMessage containerStyle={EmptyContainerStyle} messageHeading="No Notifications Found" />;
  }

  const getServicesWindow = (loading, servicesListValues) => {
    if (loading) {
      <LoadingWindow>
        <LoadingComponent customStyle={{ fontSize: '3rem', color: theme.colors.primary.main }} />
      </LoadingWindow>;
    }
    if (isEmpty(servicesListValues)) {
      return <EmptyMessage messageHeading="No services Found" containerStyle={EmptyContainerStyle} />;
    }
    return <ServiceComponent services={servicesListValues} />;
  };

  return (
    <Page variant={variants.services} header="Services" title="Services" loading={servicesInfo?.isLoading}>
      <Container>
        <Left>
          <Types serviceTypes={serviceTypes} />
          <Categories serviceCategories={serviceCategories} />
        </Left>
        <Right>
          <Title>Services</Title>
          <Box>
            <SearchWidget
              inputStyle={{
                height: '45px',
                minWidth: '400px',
                maxWidth: '400px',
                backgroundColor: `${theme.backgroundColors.white}`,
                border: `2px solid ${theme.colors.miscellaneous.greyLightest}`,
              }}
              placeHolder="Search Services By Name"
              onChange={handleSearch}
            />
            <ServicesFilter />
          </Box>
          {getServicesWindow(servicesInfo?.isLoading || loading, searchFilteredServices)}
        </Right>
      </Container>
    </Page>
  );
};

export default Services;
