import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { promotionsInitialState } from './states';
import { convertArrayToHashMap } from '../helpers/component';

const initialState = promotionsInitialState;

export const fetchPromotionsAsync = createAsyncThunk('promotions/fetchAsync', async (input, thunkApi) => {
  const { successCallback, errorCallback } = input;
  const { getState, rejectWithValue } = thunkApi;
  const { user } = getState();
  const config = {
    headers: {
      authorization: `Bearer ${user.accessToken}`,
    },
  };

  try {
    const promotions = await axios.get(`${process.env.REACT_APP_API_END_POINT}announcement/`, config);
    successCallback();
    return convertArrayToHashMap(promotions.data.content, '_id');
  } catch (error) {
    errorCallback(error);
    return rejectWithValue(error.response.data);
  }
});

const promotionsSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPromotionsAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPromotionsAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.value = action.payload;
    });
    builder.addCase(fetchPromotionsAsync.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default promotionsSlice.reducer;
