import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import { useToasts } from 'react-toast-notifications';
import Page from '../components/Page';
import variants from '../utils/variants';
import theme from '../utils/theme';
import CustomersList from '../components/CustomersList';
import SearchWidget from '../components/SearchWidget';
import { convertHashMapToArray, errorLogger } from '../utils/helpers/component';
import LoadingComponent from '../components/Adapters/LoadingComponent';
import EmptyMessage from '../components/EmptyMessage';

const LoadingWindow = styled.div`
  min-height: calc(100vh - 100px);
  width: 100%;
  flex: 1;
  display: grid;
  place-items: center;
  border-radius: 15px;
`;

const EmptyContainerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const Customers = () => {
  const { addToast } = useToasts();
  const customers = useSelector((state) => state?.customers);
  const [searchFilteredCustomers, setSearchFilteredCustomers] = useState(convertHashMapToArray(customers.value));
  const [loading, setLoading] = useState(true);

  const handleSearch = (searchKey) => {
    setLoading(true);
    try {
      const customersList = convertHashMapToArray(customers.value);
      const tempCustomers = customersList.filter((customer) => {
        const customerFirstName = customer?.first_name ?? '';
        const customerLastName = customer?.last_name ?? '';
        const email = customer?.email ?? '';
        const mobileNumber = customer?.mobile_number ?? '';
        const customerId = customer?._id ?? '';
        const regex = RegExp(searchKey, 'i');
        return (
          customerId.search(regex) !== -1 ||
          customerFirstName.search(regex) !== -1 ||
          customerLastName.search(regex) !== -1 ||
          email.search(regex) !== -1 ||
          mobileNumber.search(regex) !== -1
        );
      });
      setSearchFilteredCustomers(tempCustomers);
    } catch (error) {
      errorLogger(error, addToast, () => {});
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      setLoading(true);
      setSearchFilteredCustomers(convertHashMapToArray(customers?.value));
    } catch (error) {
      errorLogger(error, addToast, () => {});
    } finally {
      setLoading(false);
    }
  }, [customers.value]);

  const getCustomerWindow = (loading, customersListValue) => {
    if (loading) {
      return (
        <LoadingWindow>
          <LoadingComponent customStyle={{ fontSize: '3rem', color: theme.colors.primary.main }} />
        </LoadingWindow>
      );
    }
    if (isEmpty(customers)) {
      return <EmptyMessage messageHeading="No Customers Found" containerStyle={EmptyContainerStyle} />;
    }
    return <CustomersList customers={customersListValue} />;
  };

  return (
    <Page variant={variants.general} loading={customers.isLoading} header="Customers" title="Customers">
      <Box>
        <SearchWidget
          inputStyle={{
            height: '50px',
            minWidth: '400px',
            maxWidth: '400px',
            backgroundColor: `${theme.backgroundColors.white}`,
          }}
          placeHolder="Search Customer By Name, Id, Phone Number, Email"
          onChange={handleSearch}
        />
      </Box>
      {getCustomerWindow(customers.isLoading || loading, searchFilteredCustomers)}
    </Page>
  );
};

export default Customers;
