import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';
import { isEmpty } from 'lodash';

import CustomButton from '../components/Adapters/CustomButton';
import GaragesList from '../components/GaragesList';
import Page from '../components/Page';
import { convertHashMapToArray, errorLogger } from '../utils/helpers/component';
import { AddGarageRoute } from '../utils/redirects';
import theme from '../utils/theme';
import variants from '../utils/variants';
import SearchWidget from '../components/SearchWidget';
import EmptyMessage from '../components/EmptyMessage';
import LoadingComponent from '../components/Adapters/LoadingComponent';

const Container = styled.div`
  margin-top: 105px;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Bottom = styled.div`
  margin-top: 40px;
`;

const Span = styled.span``;

const Box = styled.div``;

const buttonStyle = {
  backgroundColor: theme.colors.primary.main,
  border: 'none',
  boxShadow:
    '0px 109.322px 87.4576px rgba(41, 72, 152, 0.05), 0px 70.8569px 51.2194px rgba(41, 72, 152, 0.037963), 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 21.8644px 14.2119px rgba(41, 72, 152, 0.025), 0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296), 0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037)',
  padding: '10px 30px',
  borderRadius: '8px',
  color: theme.colors.miscellaneous.white,
  fontWeight: '600',
  cursor: 'pointer',
};

const EmptyContainerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const LoadingWindow = styled.div`
  min-height: calc(100vh - 100px);
  width: 100%;
  flex: 1;
  display: grid;
  place-items: center;
  border-radius: 15px;
`;

const Garages = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const garages = useSelector((state) => state?.garages);
  const [filteredGarages, setFilteredGarages] = useState(convertHashMapToArray(garages?.value));
  const [loading, setLoading] = useState(true);

  const handleNavigateToAddGarage = () => {
    navigate(AddGarageRoute);
  };

  const handleSearch = (searchKey) => {
    setLoading(true);
    try {
      const allGarages = convertHashMapToArray(garages.value);
      const tempGarages = allGarages.filter((garage) => {
        const garageName = garage?.name ?? '';
        const garageContact = garage?.contact_number ?? '';
        const garageEmail = garage?.email ?? '';
        const regex = new RegExp(searchKey, 'i');
        return (
          garageName.search(regex) !== -1 || garageContact.search(regex) !== -1 || garageEmail.search(regex) !== -1
        );
      });
      setFilteredGarages(tempGarages);
    } catch (error) {
      errorLogger(error, addToast, () => {});
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      setLoading(true);
      setFilteredGarages(convertHashMapToArray(garages?.value));
    } catch (error) {
      errorLogger(error, addToast, () => {});
    } finally {
      setLoading(false);
    }
  }, [garages.value]);

  const getGaragesWindow = (loading, garagesListValue) => {
    if (loading) {
      return (
        <LoadingWindow>
          <LoadingComponent customStyle={{ fontSize: '3rem', color: theme.colors.primary.main }} />
        </LoadingWindow>
      );
    }
    if (isEmpty(garagesListValue)) {
      return <EmptyMessage messageHeading="No Garages Found" containerStyle={EmptyContainerStyle} />;
    }
    return <GaragesList garages={garagesListValue} />;
  };

  return (
    <Page variant={variants.garage} loading={garages.isLoading} header="Garages" title="Garages">
      <Container>
        <Top>
          <Box>
            <SearchWidget
              inputStyle={{
                height: '50px',
                minWidth: '400px',
                maxWidth: '400px',
                backgroundColor: `${theme.backgroundColors.white}`,
              }}
              placeHolder="Search Garage By Name, Phone Number, Email"
              onChange={handleSearch}
            />
          </Box>
          <CustomButton variant={variants.primary} customStyle={buttonStyle} onClick={handleNavigateToAddGarage}>
            <Span style={{ marginRight: '10px' }}>
              <i className="fa-solid fa-plus" />
            </Span>
            New Garage
          </CustomButton>
        </Top>
        <Bottom>{getGaragesWindow(garages.isLoading || loading, filteredGarages)}</Bottom>
      </Container>
    </Page>
  );
};

export default Garages;
