import dashboardIcon from '../assets/images/svg/category.svg';
import garageIcon from '../assets/images/svg/garage.svg';
import customers from '../assets/images/svg/customers.svg';
import serviceIcon from '../assets/images/svg/service.svg';
import ticketIcon from '../assets/images/svg/ticket.svg';
import reportBugIcon from '../assets/images/svg/report-bug.svg';
import AnnouncementIcon from '../assets/images/svg/promotion.svg';

export const NAV_SIDE_BAR_CONFIG = [
  { id: 1, title: 'Dashboard', path: '/', icon: dashboardIcon },
  {
    id: 2,
    title: 'Garages',
    path: '/garages',
    icon: garageIcon,
  },
  { id: 3, title: 'Customers', path: '/customers', icon: customers },
  { id: 4, title: 'Services', path: '/services', icon: serviceIcon },
  { id: 5, title: 'Promo Codes', path: '/promo-codes', icon: ticketIcon },
  { id: 6, title: 'Promotions', path: '/promotions', icon: AnnouncementIcon },
  { id: 7, title: 'Bug Reports', path: '/bug-reports', icon: reportBugIcon },
];

export const errorToastOptions = { appearance: 'error', autoDismiss: true };

export const warningToastOptions = { appearance: 'warning', autoDismiss: true };

export const successToastOptions = { appearance: 'success', autoDismiss: true };

export const DASHBOARD_SALES_GRAPH_FILTERS = [
  { id: 1, name: 'Days', title: 'Daily', value: 'daily' },
  { id: 2, name: 'Weeks', title: 'Weekly', value: 'weekly' },
  { id: 3, name: 'Months', title: 'Monthly', value: 'monthly' },
];
