import styled from 'styled-components';
import { useEffect, useState } from 'react';
import Page from '../components/Page';
import variants from '../utils/variants';

import { decodeDate } from '../utils/helpers/date';
import { getBugReports } from '../config/firebaseConfig';

const urgencyBorders = {
  high: '1px solid red',
  medium: '1px solid orange',
  low: '1px solid yellow',
};

const Container = styled.div`
  height: 80vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ReportContainer = styled.div`
  border: ${(props) => urgencyBorders[props.urgency]};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 12px 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: inherit;
`;

const Text = styled.p``;

const Bold = styled.span`
  font-weight: 700;
`;

const OpenScreenshot = styled.a`
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 2px;
`;

const Screenshot = styled.img`
  height: 200px;
  width: 350px;
  object-fit: cover;
  object-position: top;
`;

const BugReports = () => {
  const [reportData, setReportData] = useState(null);

  useEffect(() => {
    (async () => {
      const data = await getBugReports();
      // sort by date and then set to state
      data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setReportData(data);
    })();
  }, []);

  return (
    <Page variant={variants.general} header="BugReports" title="BugReports">
      <Container>
        {reportData &&
          reportData?.map((e, index) => (
            <ReportContainer urgency={e.urgency} key={index}>
              <Content>
                <Text>
                  <Bold>Date:</Bold> {decodeDate(e.date)}
                </Text>
                <Text>
                  <Bold>Description:</Bold> {e.description ? e.description : 'No description'}
                </Text>
                <Text>
                  <Bold>Urgency:</Bold> {e.urgency}
                </Text>
              </Content>
              <OpenScreenshot href={e.imageUrl} target="_blank">
                <Screenshot src={e.imageUrl} />
              </OpenScreenshot>
            </ReportContainer>
          ))}
      </Container>
    </Page>
  );
};

export default BugReports;
