import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Input = styled.input`
  border: ${(props) => `2px solid ${props.theme.colors.miscellaneous.white}`};
  background-color: ${(props) => props.theme.backgroundColors.blueCyan1};
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 400;
  padding: 0 14px;
  margin-top: 10px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  &:focus {
    outline: ${(props) => `2px solid ${props.theme.colors.primary.main}`};
    border: 1px solid transparent !important;
  }
`;

const ErrorText = styled.span`
  color: ${(props) => props.theme.colors.miscellaneous.red};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-xs']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-xs']};
`;

const CustomInput = (props) => {
  const { name, value, placeHolder, onChange, type, style, required, ...rest } = props;

  const [error, setError] = useState({ status: false, message: '' });

  const validateType = (type, value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    switch (type) {
      case 'email':
        const result = emailPattern.test(value);
        if (!result) {
          setError({ status: true, message: `Invalid Email` });
        }
        break;
      default:
        break;
    }
  };

  const handleBlur = (type, value) => {
    if (required) {
      if (!value) {
        return setError({ status: true, message: `${name} is required` });
      }
      return validateType(type, value);
    }
    if (value) {
      return validateType(type, value);
    }
  };

  return (
    <>
      <Input
        name={name}
        value={value}
        placeholder={placeHolder}
        required={required}
        onChange={(e) => {
          setError({ status: false, message: '' });
          onChange(e);
        }}
        type={type}
        onBlur={() => {
          handleBlur(type, value);
        }}
        style={{ ...style }}
        {...rest}
      />
      {error.status && <ErrorText>{error.message}</ErrorText>}
    </>
  );
};

CustomInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string || PropTypes.number,
  placeHolder: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
  style: PropTypes.object,
};

CustomInput.defaultProps = {
  name: '',
  value: '',
  placeHolder: '',
  required: true,
  onChange: () => {},
  type: 'text',
  style: {},
};

export default CustomInput;
