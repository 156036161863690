import React, { useReducer, useState } from 'react';
import styled from 'styled-components';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import variants from '../../utils/variants';
import theme from '../../utils/theme';
import CustomInput from '../Adapters/CustomInput';
import CustomButton from '../Adapters/CustomButton';
import { BUTTON } from '../../utils/localization';

import { convertHashMapToArray, errorLogger } from '../../utils/helpers/component';
import { capitalize } from '../../utils/helpers/string';
import { errorToastOptions, successToastOptions, warningToastOptions } from '../../utils/constants';
import apiInstance from '../../utils/apiInstance';

const Container = styled.form``;

const Label = styled.label`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 400;
`;

const ErrorText = styled.span`
  color: ${(props) => props.theme.colors.miscellaneous.red};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-xs']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-xs']};
`;

const inputStyle = {
  height: '50px',
  border: `1px solid ${theme.colors.miscellaneous.greyLightest}`,
  borderRadius: '5px',
  backgroundColor: theme.colors.miscellaneous.white,
  marginTop: '0',
};

const submitButtonStyle = {
  border: 'none',
  borderRadius: '8px',
  padding: '10px 30px',
  boxShadow: 'none',
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: 600,
};

const cancelButtonStyle = {
  border: 'none',
  borderRadius: '8px',
  padding: '10px 30px',
  boxShadow: 'none',
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: 600,
  color: theme.colors.secondary.main,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateManager':
      return { ...state, manager: { ...state.manager, [action.payload.key]: action.payload.value } };
    default:
      break;
  }
};

const EditManager = () => {
  const { addToast } = useToasts();
  const garages = useSelector((state) => state?.garages);
  const navigate = useNavigate();
  const params = useParams();

  const { garageId } = params;

  const allGarages = convertHashMapToArray(garages?.value);
  const garage = allGarages.filter((garage) => garage._id === garageId)[0];

  const initialState = {
    manager: {
      first_name: garage?.manager?.first_name,
      last_name: garage?.manager?.last_name,
      mobile_number: garage?.manager?.mobile_number,
      email: garage?.manager?.email,
    },
  };

  const [state, localDispatch] = useReducer(reducer, initialState);

  const [errors, setErrors] = useState({
    managerMobileNumber: {
      status: false,
      message: '',
    },
  });
  const [isManagerDetailsUpdated, setIsManagerDetailsUpdated] = useState(false);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  const updateManager = (key, value) => {
    setIsManagerDetailsUpdated(true);
    localDispatch({ type: 'updateManager', payload: { key, value } });
  };

  const handleManagerNumberInput = (e) => {
    setErrors((state) => ({ ...state, managerMobileNumber: { status: false, error: '' } }));
    setIsManagerDetailsUpdated(true);
    updateManager('mobile_number', `+${e}`);
  };

  const handleManagerNumberInputBlur = () => {
    if (!isPossiblePhoneNumber(state.manager.mobile_number)) {
      setErrors((state) => ({ ...state, managerMobileNumber: { status: true, error: 'Invalid Phone Number' } }));
    }
  };

  const handleCloseEditManager = () => {
    navigate(`/garage/${garage._id}`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isManagerDetailsUpdated) {
      if (isPossiblePhoneNumber(state?.manager?.mobile_number)) {
        setSubmitButtonLoading(true);
        const body = {
          ...state?.manager,
        };
        try {
          await apiInstance.patch(`manager/admin/update/${garage.manager._id}?manager_id=${garage.manager._id}`, body);
          window.location.href = `/garage/${garage._id}`;
          setIsManagerDetailsUpdated(false);
          addToast('Updated garage details successfully', successToastOptions);
        } catch (error) {
          errorLogger(error, addToast);
        } finally {
          setSubmitButtonLoading(false);
        }
      } else {
        return addToast('Invalid contact number', errorToastOptions);
      }
    } else {
      addToast('No changes to update', warningToastOptions);
    }
  };

  return (
    <Container onSubmit={handleSubmit}>
      <i
        className="fa-sharp fa-solid fa-arrow-left fa-lg"
        style={{
          color: theme.colors.secondary.main,
          fontSize: '1.3rem',
          cursor: 'pointer',
          lineHeight: '3rem',
        }}
        onClick={handleCloseEditManager}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <Label>Mobile Number</Label>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <PhoneInput
                country={'ca'}
                enableLongNumbers
                enableSearch
                containerClass={'phoneInput2ContainerEditManagerProfile'}
                inputClass={'phoneInput2InputEditManagerProfile'}
                value={
                  state?.manager?.mobile_number.charAt(0) === '+'
                    ? state?.manager?.mobile_number.substring(1)
                    : state?.manager?.mobile_number
                }
                onChange={handleManagerNumberInput}
                onBlur={handleManagerNumberInputBlur}
              />
              {errors.managerMobileNumber.status && <ErrorText>{errors.managerMobileNumber.error}</ErrorText>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} lg={6} xl={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <Label>Email Address *</Label>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <CustomInput
                placeHolder="johndeo@gmail.com"
                type="email"
                required
                style={inputStyle}
                value={state?.manager?.email}
                onChange={(e) => {
                  updateManager('email', e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <Label>First Name *</Label>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <CustomInput
                placeHolder="First Name"
                type="text"
                required
                style={inputStyle}
                value={state?.manager?.first_name}
                onChange={(e) => {
                  updateManager('first_name', capitalize(e.target.value));
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <Label>Last Name *</Label>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <CustomInput
                placeHolder="Last Name"
                type="text"
                required
                style={inputStyle}
                value={state?.manager?.last_name}
                onChange={(e) => {
                  updateManager('last_name', capitalize(e.target.value));
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} lg={6} xl={16} />
      </Grid>
      <Grid container spacing={1} sx={{ padding: '30px 0 30px 0' }}>
        <Grid item>
          <CustomButton
            variant={variants.primary}
            customStyle={submitButtonStyle}
            loading={submitButtonLoading}
            loaderStyle={{ fontSize: theme.fontSizes['text-sm'] }}
          >
            Update
          </CustomButton>
        </Grid>
        <Grid item>
          <CustomButton
            variant={variants.secondary}
            customStyle={cancelButtonStyle}
            type={BUTTON}
            onClick={handleCloseEditManager}
          >
            Cancel
          </CustomButton>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EditManager;
