const { default: apiInstance } = require('../apiInstance');
const { errorLogger } = require('./component');

export const fetchServiceByTypeAndCategory = async ({ typeId, categoryId, addToast }) => {
  try {
    const services = await apiInstance.get(
      `baseService/filterBy/type/category?type_id=${typeId}&category_id=${categoryId || ''}`
    );
    return services.data.content;
  } catch (error) {
    errorLogger(error, addToast);
  }
};
