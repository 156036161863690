import React from 'react';
import PropTypes from 'prop-types';

import { StyledBodyTableCell, StyledTableBodyRow } from '../../utils/styledComponents';
import theme from '../../utils/theme';

const bodyFirstTableCellStyle = {
  color: theme.colors.secondary.darker,
  fontFamily: theme.fontFamilies.lato,
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: '500',
  borderRadius: '8px 0 0 8px',
  border: 'none',
};

const bodyLastTableCellStyle = {
  color: theme.colors.secondary.darker,
  fontFamily: theme.fontFamilies.lato,
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: '500',
  borderRadius: '0 8px 8px 0',
  border: 'none',
};

const bodyTableCellStyle = {
  color: theme.colors.secondary.darker,
  fontFamily: theme.fontFamilies.lato,
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: '500',
  border: 'none',
};

const bodyTableCellBodyStyle = {
  color: theme.colors.secondary.darker,
  fontFamily: theme.fontFamilies.lato,
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: '500',
  border: 'none',
  width: '30%',
};

const PromotionItem = (props) => {
  const { promotion, page, rowsPerPage, index } = props;
  return (
    <StyledTableBodyRow style={{ backgroundColor: theme.backgroundColors.white, borderRadius: '8px' }}>
      <StyledBodyTableCell sx={bodyFirstTableCellStyle}> {page * rowsPerPage + index + 1}</StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle}>{promotion?.garage_id?.name}</StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle}>{promotion?.title}</StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellBodyStyle}>{promotion?.body}</StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyLastTableCellStyle}>
        {new Date(promotion?.datetime).toDateString()}, {new Date(promotion?.datetime).toLocaleTimeString()}
      </StyledBodyTableCell>
    </StyledTableBodyRow>
  );
};

PromotionItem.propTypes = {
  promotion: PropTypes.object,
  index: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
};

export default PromotionItem;
