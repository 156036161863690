import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { convertArrayToHashMap } from '../helpers/component';
import { promoCodesInitialState } from './states';

const initialState = promoCodesInitialState;

export const fetchPromocodesAsync = createAsyncThunk('promoCodes/fetchAsync', async (input, thunkApi) => {
  const { successCallback, errorCallback } = input;
  const { getState, rejectWithValue } = thunkApi;
  const { user } = getState();
  const config = {
    headers: {
      authorization: `Bearer ${user.accessToken}`,
    },
  };
  try {
    const customers = await axios.get(`${process.env.REACT_APP_API_END_POINT}promocode/`, config);
    successCallback();
    return convertArrayToHashMap(customers.data.content, '_id');
  } catch (error) {
    errorCallback(error);
    return rejectWithValue(error.response.data);
  }
});

const promoCodesSlice = createSlice({
  name: 'promoCodes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPromocodesAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPromocodesAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.value = action.payload;
    });
    builder.addCase(fetchPromocodesAsync.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default promoCodesSlice.reducer;
