import { REDUX_PERSIST } from './localization';

export const AddUserPersistance = (value) => {
  try {
    const user = { user: value };
    window.localStorage.setItem(REDUX_PERSIST, JSON.stringify(user));
  } catch (error) {
    console.log(error);
  }
};

export const removeUserPersistace = () => {
  try {
    window.localStorage.clear(REDUX_PERSIST);
  } catch (error) {
    console.log(error);
  }
};
