import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-input-2/lib/style.css';

import Page from '../components/Page';
import variants from '../utils/variants';
import { errorLogger, uploadMediaToFireBase } from '../utils/helpers/component';
import apiInstance from '../utils/apiInstance';
import { garagesRoute } from '../utils/redirects';
import AddGarageComponent from '../components/AddGarage';
import { capitalize } from '../utils/helpers/string';

const AddGarage = () => {
  const { addToast } = useToasts();

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleSubmit = async (state) => {
    const isGarageNumberPossible = isPossiblePhoneNumber(`+${state?.garage?.contact_number}`);
    const isManagerNumberPossible = isPossiblePhoneNumber(`+${state?.manager?.mobile_number}`);
    if (state?.garage?.logo && isGarageNumberPossible && isManagerNumberPossible) {
      try {
        setSubmitLoading(true);
        const logoUrl = await uploadMediaToFireBase(state?.garage?.logo);
        const manager = {
          ...state.manager,
          mobile_number: `+${state?.manager?.mobile_number}`,
          first_name: capitalize(state?.manager?.first_name),
          last_name: capitalize(state?.manager?.last_name),
        };
        const address = {
          ...state.address,
          city: capitalize(state?.address?.city),
          provinance: capitalize(state?.address?.provinance),
          street: capitalize(state?.address?.street),
        };
        const garage = {
          ...state?.garage,
          logo: logoUrl,
          contact_number: `+${state?.garage?.contact_number}`,
          business_number: `+${state?.garage?.business_number}`,
          location: { type: 'Point', coordinates: [state?.address?.lat, state?.address?.lng] },
          name: capitalize(state?.garage?.name),
        };
        const body = {
          manager: { ...manager },
          address,
          ...garage,
        };
        await apiInstance.post('garages/create', body);
        window.location.href = garagesRoute;
      } catch (error) {
        errorLogger(error, addToast, () => {});
      } finally {
        setSubmitLoading(false);
      }
    } else if (!state.garage.logo) {
      errorLogger({ message: 'Logo is required' }, addToast, () => {});
    } else if (!isGarageNumberPossible) {
      errorLogger({ message: 'Garage number invalid' }, addToast, () => {});
    } else {
      errorLogger({ message: 'Manager number invalid' }, addToast, () => {});
    }
  };

  return (
    <Page variant={variants.addGarage} header="Garages" title="Garages">
      <AddGarageComponent handleSubmit={handleSubmit} submitLoading={submitLoading} />
    </Page>
  );
};

export default AddGarage;
