import styled from 'styled-components';

const Container = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  height: 100px;
  width: 100%;
  padding: 35px 20px 0 145px;
  background-color: ${(props) => props.theme.backgroundColors.blueCyan1};
  z-index: 95;
  @media screen and (max-width: 1200px) {
    padding: 35px 20px 0 120px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div``;

const Heading = styled.h2`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-4xl']};
  line-height: ${(props) => props.theme.lineHeights['text-4xl']};
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

const GarageHeader = () => (
  <Container>
    <Wrapper>
      <Left>
        <Heading>Garages</Heading>
      </Left>
    </Wrapper>
  </Container>
);

export default GarageHeader;
