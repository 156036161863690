import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Drawer } from '@mui/material';

const Container = styled.div`
  height: 100vh;
  background-color: ${(props) => props.theme.backgroundColors.blueCyan1};
  padding: 30px;
  overflow: auto;
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const DrawerHeading = styled.h5`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-2xl']};
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights['text-2xl']};
`;

const CloseIcon = styled.span`
  padding: 6px 10px;
  border: ${(props) => `1px solid ${props.theme.colors.secondary.main}`};
  border-radius: 5px;
  cursor: pointer;
`;

const Children = styled.div`
  margin-top: 20px;
`;

const CustomDrawer = (props) => {
  const { open, handleClose, anchor, containerStyle, children, heading } = props;

  return (
    <Drawer open={open} onClose={handleClose} anchor={anchor}>
      <Container style={{ ...containerStyle }}>
        <HeadingContainer>
          <DrawerHeading>{heading}</DrawerHeading>
          <CloseIcon onClick={handleClose}>
            <i className="fa-sharp fa-solid fa-xmark" />
          </CloseIcon>
        </HeadingContainer>
        <Children>{children}</Children>
      </Container>
    </Drawer>
  );
};

CustomDrawer.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  anchor: PropTypes.string,
  containerStyle: PropTypes.object,
  children: PropTypes.element,
  heading: PropTypes.string,
};

CustomDrawer.defaultProps = {
  open: false,
  handleClose: () => {},
  anchor: 'right',
  containerStyle: {},
  children: <div />,
  heading: 'Heading',
};

export default CustomDrawer;
