import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import AuthDetails from '../components/Authentication/AuthDetails';
import AuthIntro from '../components/Authentication/AuthIntro';

const Container = styled.div``;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 100vh;
  align-items: center;
  justify-items: center;
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }
`;

const Login = () => (
  <Container>
    <Helmet>
      <title>Mxpert Admin | Login</title>
    </Helmet>
    <Wrapper>
      <AuthIntro />
      <AuthDetails />
    </Wrapper>
  </Container>
);

export default Login;
