import styled from 'styled-components';

import Page from '../components/Page';
import variants from '../utils/variants';
import EditGarageProfile from '../components/EditGarageProfile';

const Container = styled.div`
  width: 100%;
`;

const Editgarage = () => (
  <Page variant={variants.general} header="Update Garage" title="Update Garage">
    <Container>
      <EditGarageProfile />
    </Container>
  </Page>
);

export default Editgarage;
