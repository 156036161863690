import styled from 'styled-components';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

import theme from '../../utils/theme';
import SearchIcon from '../../assets/images/svg/search.svg';

const SearchContainer = styled.div`
  position: relative;
  max-width: 400px;
`;

const SearchInput = styled.input`
  outline: none;
  border: none;
  background-color: #e0ecf1;
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 500;
  line-height: ${(props) => props.theme.lineHeights['text-sm']};
  height: 100%;
  padding: 0 40px 0 5px;
  border: 2px solid #fafafa;
  border-radius: 8px;
  width: 100%;
  position: relative;
  &:focus {
    outline: transparent;
    border: ${(props) => `2px solid ${props.theme.colors.primary.main} !important`};
  }
`;

const closeIconStyle = {
  color: theme.colors.secondary.light,
  position: 'absolute',
  right: '8px',
  top: '50%',
  transform: 'translateY(-50%)',
  cursor: 'pointer',
  fontSize: '24px',
  borderRadius: '50%',
  border: `1px solid ${theme.colors.secondary.light}`,
  padding: '2.5px',
};

const Image = styled.img`
  margin: 0 5px 0 0;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
`;

const SearchWidget = (props) => {
  const { placeHolder, onChange, containerStyle, inputStyle, iconStyle } = props;
  const [searchKeyWord, setSearchKeyWord] = useState('');

  const handleSearchChange = (e) => {
    setSearchKeyWord(e.target.value);
    onChange(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchKeyWord('');
    onChange('');
  };

  return (
    <SearchContainer style={{ ...containerStyle }}>
      <SearchInput
        onChange={handleSearchChange}
        style={{ ...inputStyle }}
        placeholder={placeHolder}
        value={searchKeyWord}
        spellCheck="false"
      />
      {searchKeyWord.length > 0 ? (
        <CloseIcon style={closeIconStyle} onClick={handleClearSearch} />
      ) : (
        <Image src={SearchIcon} style={{ ...iconStyle }} />
      )}
    </SearchContainer>
  );
};

SearchWidget.propTypes = {
  placeHolder: PropTypes.string,
  onChange: PropTypes.func,
  containerStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  iconStyle: PropTypes.object,
};

SearchWidget.defaultProps = {
  placeHolder: 'Search',
  onChange: () => {},
  containerStyle: {},
  inputStyle: {},
  iconStyle: {},
};

export default SearchWidget;
