import { throttle } from 'lodash';

import store from '../../store';
import { saveState } from '../helpers/redux';

export const useStore = () => {
  store.subscribe(() => {
    throttle(() => {
      saveState(store.getState());
    }, 1000)();
  });
};
