const { createSlice } = require('@reduxjs/toolkit');
const { modalInitialState } = require('./states');

const initialState = modalInitialState;

const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openAddTypeModal: (state) => {
      state.addTypeModal = true;
    },
    closeAddTypeModal: (state) => {
      state.addTypeModal = false;
    },
    openAddCategoryModal: (state) => {
      state.addCategoryModal = true;
    },
    closeAddCategoryModal: (state) => {
      state.addCategoryModal = false;
    },
    openUserProfileModal: (state) => {
      state.userProfileModal = true;
    },
    closeUserProfileModal: (state) => {
      state.userProfileModal = false;
    },
    openCreateServiceModal: (state) => {
      state.createServiceModal = true;
    },
    closeCreateServiceModal: (state) => {
      state.createServiceModal = false;
    },
  },
});

export const {
  openAddTypeModal,
  closeAddTypeModal,
  openAddCategoryModal,
  closeAddCategoryModal,
  openUserProfileModal,
  closeUserProfileModal,
  openCreateServiceModal,
  closeCreateServiceModal,
} = modalSlice.actions;

export default modalSlice.reducer;
