import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import apiInstance from '../../utils/apiInstance';
import { errorLogger, convertHashMapToArray, uploadMediaToFireBase } from '../../utils/helpers/component';
import theme from '../../utils/theme';
import variants from '../../utils/variants';
import CustomButton from '../Adapters/CustomButton';
import CustomInput from '../Adapters/CustomInput';
import CustomDropDown from '../Adapters/CustomDropDown';
import { TYPE, _ID } from '../../utils/localization';
import garageLogoInputIcon from '../../assets/images/svg/garage-logo-input.svg';
import { closeAddCategoryModal } from '../../utils/redux/modalSlice';
import { fetchCategoriesAsync } from '../../utils/redux/categoriesSlice';
import { successToastOptions } from '../../utils/constants';

const Container = styled.div``;

const Box = styled.div``;

const Form = styled.form``;

const Title = styled.p`
  color: ${(props) => props.theme.colors.secondary.darker};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  line-height: ${(props) => props.theme.lineHeights['text-base']};
  font-weight: 600;
  margin-bottom: 15px;
`;

const Label = styled.label`
  background-color: ${(props) => props.theme.backgroundColors.blueCyan1};
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 600;
  margin-bottom: 5px;
`;

const Image = styled.img`
  cursor: pointer;
  height: 160px !important;
  width: 160px !important;
  object-fit: cover;
  width: auto;
  cursor: pointer;
`;

const Input = styled.input`
  display: none;
`;

const createButtonStyle = {
  backgroundColor: theme.colors.primary.main,
  border: 'none',
  boxShadow:
    '0px 109.322px 87.4576px rgba(41, 72, 152, 0.05), 0px 70.8569px 51.2194px rgba(41, 72, 152, 0.037963), 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 21.8644px 14.2119px rgba(41, 72, 152, 0.025), 0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296), 0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037)',
  padding: '10px 30px',
  borderRadius: '8px',
  color: theme.colors.miscellaneous.white,
  fontWeight: '600',
  cursor: 'pointer',
  margin: '10px',
};

const cancelButtonStyle = {
  border: '1px solid #D0D5DD',
  boxShadow:
    '0px 109.322px 87.4576px rgba(41, 72, 152, 0.05), 0px 70.8569px 51.2194px rgba(41, 72, 152, 0.037963), 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 21.8644px 14.2119px rgba(41, 72, 152, 0.025), 0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296), 0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037)',
  padding: '10px 30px',
  borderRadius: '8px',
  color: theme.colors.secondary.darker,
  fontWeight: '600',
  cursor: 'pointer',
  margin: '10px',
};

const dropDownStyle = {
  height: '50px',
  marginBottom: '15px',
  border: `1px solid ${theme.colors.miscellaneous.white}`,
  borderRadius: '8px',
  fontWeight: 400,
  backgroundColor: theme.backgroundColors.white,
};

const inputStyle = {
  height: '50px',
  marginBottom: '10px',
  border: `1px solid ${theme.colors.miscellaneous.white}`,
  backgroundColor: theme.backgroundColors.white,
};

const AddCategoryModalContent = () => {
  const [submitLoading, setSubmitLoading] = useState(false);

  const dispatch = useDispatch();
  const serviceTypes = useSelector((state) => state?.serviceTypes);
  const [selectedType, setSelectedType] = useState([]);
  const [category, setCategory] = useState('');
  const [icon, setIcon] = useState('');
  const { addToast } = useToasts();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    if (isEmpty(selectedType)) {
      errorLogger({ message: 'Service Type Is Not Selected' }, addToast, () => {
        setSubmitLoading(false);
      });
    } else {
      try {
        const iconUrl = await uploadMediaToFireBase(icon);
        await apiInstance.post('categories/create', {
          icon: iconUrl,
          category,
          type_id: selectedType[0]?._id,
        });
        addToast('Added Type Successfully', successToastOptions);
        dispatch(
          fetchCategoriesAsync({
            successCallback: () => {},
            errorCallback: (error) => {
              errorLogger(error, addToast);
            },
            value: { config: {} },
          })
        );
        dispatch(closeAddCategoryModal());
        setSubmitLoading(false);
      } catch (error) {
        errorLogger(error, addToast, () => {
          setSubmitLoading(false);
        });
      }
    }
  };

  return (
    <Container>
      {!serviceTypes.isLoading && (
        <Form onSubmit={handleSubmit}>
          <Box>
            <Title>Select Type</Title>
            <CustomDropDown
              options={convertHashMapToArray(serviceTypes.value)}
              labelField={TYPE}
              valueField={_ID}
              values={selectedType}
              onChange={(e) => {
                setSelectedType(e);
              }}
              style={dropDownStyle}
            />
          </Box>
          <Box>
            <Title>Category Name</Title>
            <CustomInput
              required
              name="Category Name"
              placeHolder="Name"
              style={inputStyle}
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
              type="text"
            />
          </Box>
          <Box>
            <Title>Upload Icon</Title>
            <Label htmlFor="garageLogo">
              <Image src={icon ? URL.createObjectURL(icon) : garageLogoInputIcon} alt="Garage Logo" />
            </Label>
            <Input
              id="garageLogo"
              type="file"
              accept=".jpg, .jpeg, .png"
              onChange={(e) => {
                setIcon(e.target.files[0]);
              }}
            />
          </Box>

          <Grid container justifyContent="center" style={{ marginTop: '50px' }}>
            <CustomButton
              type="button"
              variant={variants.secondary}
              customStyle={cancelButtonStyle}
              onClick={() => {
                dispatch(closeAddCategoryModal());
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              type="submit"
              variant={variants.primary}
              loading={submitLoading}
              customStyle={createButtonStyle}
            >
              Create
            </CustomButton>
          </Grid>
        </Form>
      )}
    </Container>
  );
};

export default AddCategoryModalContent;
