import { useSelector } from 'react-redux';

import Page from '../components/Page';
import variants from '../utils/variants';
import SummeryContent from '../components/Dashboard/SummeryContent';

const Home = () => {
  const { user, stats } = useSelector((state) => ({
    user: state?.user,
    stats: state?.stats,
  }));

  const getGreetings = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) {
      return 'Good Morning';
    }
    if (currentHour >= 12 && currentHour < 18) {
      return 'Good Afternoon';
    }
    return 'Good Evening';
  };

  const greetMessage = getGreetings();

  return (
    <Page
      loading={stats?.isLoading}
      header={`${greetMessage}, ${user?.userDetails?.name}`}
      variant={variants.general}
      title="Home"
    >
      <SummeryContent stats={stats?.value} />
    </Page>
  );
};

export default Home;
