import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  width: 250px;
  height: 250px;
  background-color: ${(props) => props.theme.backgroundColors.blueCyan2};
  border-radius: 16px;
  padding: 40px 40px 40px 30px;
  @media (max-width: 1920px) {
    width: 14vw;
    height: 14vw;
  }
  @media (max-width: 1400px) {
    padding: 20px;
    width: 16vw;
    height: 16vw;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const Total = styled.h5`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-lg']};
  line-height: ${(props) => props.theme.lineHeights['text-base']};
  font-weight: 500;
`;

const Title = styled.h5`
  color: ${(props) => props.theme.colors.secondary.light};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-xl']};
  line-height: ${(props) => props.theme.lineHeights['text-xl']};
  font-weight: 500;
`;

const SummeryWidget = (props) => {
  const { item } = props;
  return (
    <Container>
      <Wrapper>
        <Total>{item.value}</Total>
        <Title>{item.title}</Title>
      </Wrapper>
    </Container>
  );
};

SummeryWidget.propTypes = {
  item: PropTypes.object,
};

export default SummeryWidget;
