import apiInstance from './apiInstance';
import { errorLogger } from './helpers/component';
import { signIn } from './redux/userSlice';

export const checkAuthStateFromServer = async ({ setLoading, signOutCallback, dispatch, addToast }) => {
  try {
    const res = await apiInstance.post('auth/admin/login');
    dispatch(
      signIn({
        userDetails: { ...res.data.content },
        accessToken: res.data.content.accessToken,
        refreshToken: res.data.content.refreshToken,
      })
    );
    setLoading(false);
  } catch (error) {
    if (error?.response?.status === 498) {
      signOutCallback();
    } else {
      errorLogger(error, addToast, () => {
        setLoading(false);
      });
    }
  }
};
