import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Edit } from 'iconsax-react';
import { Link } from 'react-router-dom';

import { StyledBodyTableCell, StyledTableBodyRow } from '../../utils/styledComponents';
import theme from '../../utils/theme';
import { convertHashMapToArray } from '../../utils/helpers/component';

const bodyFirstTableCellStyle = {
  color: theme.colors.secondary.darker,
  fontFamily: theme.fontFamilies.lato,
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: '500',
  borderRadius: '8px 0 0 8px',
  border: 'none',
  padding: '25px 16px',
};

const bodyLastTableCellStyle = {
  color: theme.colors.secondary.darker,
  fontFamily: theme.fontFamilies.lato,
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: '500',
  borderRadius: '0 8px 8px 0',
  border: 'none',
  padding: '25px 16px',
};

const bodyTableCellStyle = {
  color: theme.colors.secondary.darker,
  fontFamily: theme.fontFamilies.lato,
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: '500',
  border: 'none',
  padding: '25px 16px',
};

const PromoCodeItem = (props) => {
  const { promoCode, index, page, rowsPerPage } = props;
  const garages = useSelector((state) => state?.garages);

  const garageName = convertHashMapToArray(garages.value).filter((garage) => garage._id === promoCode.garage_id)[0];

  return (
    <StyledTableBodyRow style={{ backgroundColor: theme.backgroundColors.white, borderRadius: '8px' }}>
      <StyledBodyTableCell sx={bodyFirstTableCellStyle}>{page * rowsPerPage + index + 1}</StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle}>{promoCode?.code.toUpperCase()}</StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle}>
        {new Date(promoCode?.start_date).toDateString()}
      </StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle}>{new Date(promoCode?.end_date).toDateString()}</StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle}>{new Date(promoCode?.createdAt).toDateString()}</StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyTableCellStyle}>{garageName?.name}</StyledBodyTableCell>
      <StyledBodyTableCell
        sx={{
          ...bodyTableCellStyle,
          color: `${promoCode?.active === true ? 'green' : 'red'}`,
          fontWeight: 600,
        }}
        align="center"
      >
        {promoCode?.active === true ? 'Active' : 'Inactive'}
      </StyledBodyTableCell>
      <StyledBodyTableCell sx={bodyLastTableCellStyle} align="center">
        <Link to={`editPromoCode/${promoCode._id}`}>
          <Edit style={{ cursor: 'pointer' }} size="20" color={theme.colors.secondary.main} />
        </Link>
      </StyledBodyTableCell>
    </StyledTableBodyRow>
  );
};

PromoCodeItem.propTypes = {
  promoCode: PropTypes.object,
  index: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
};

export default PromoCodeItem;
