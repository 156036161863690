import styled from 'styled-components';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';
import SummeryWidget from './SummeryWidget';
import { CURRENCY, NUMBER } from '../../utils/localization';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
`;

const SummeryContent = (props) => {
  const { stats } = props;

  const data = [
    { title: 'TOTAL GARAGES', type: NUMBER, value: stats?.garagesCount },
    { title: 'TOTAL CUSTOMERS', type: NUMBER, value: stats?.totalUsers },
    { title: 'TOTAL SERVICES', type: NUMBER, value: stats?.totelServices },
    { title: 'TOTAL BOOKINGS', type: NUMBER, value: stats?.totalBookings },
    {
      title: 'TOTAL REVENUE',
      type: CURRENCY,
      value: `$${!isEmpty(stats?.totalRevenue) ? stats?.totalRevenue[0]?.totalRevenue : 0}`,
    },
  ];
  return (
    <Container>
      {data.map((item, index) => (
        <SummeryWidget key={index} item={item} />
      ))}
    </Container>
  );
};

SummeryContent.propTypes = {
  stats: PropTypes.object,
};

export default SummeryContent;
