import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import { fetchServiceByTypeAndCategory } from '../../utils/helpers/apiFuncs';
import { convertHashMapToArray, errorLogger } from '../../utils/helpers/component';
import { CATEGORY, TYPE, _ID } from '../../utils/localization';
import { fetchServicesAsyncThunk, setServices } from '../../utils/redux/servicesSlice';
import theme from '../../utils/theme';
import CustomDropDown from '../Adapters/CustomDropDown';

const Container = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  & > p {
    font-family: ${(props) => props.theme.fontFamilies.gotham};
  }
  & > div {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    & > p {
      margin: 0;
    }
  }
`;

const Text = styled.p`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  line-height: ${(props) => props.theme.fontSizes['text-base']};
  font-weight: 400;
  margin-bottom: 15px;
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ClearText = styled.p`
  color: ${(props) => props.theme.colors.error.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  line-height: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 400;
  position: absolute;
  bottom: -20px;
  right: 0;
  text-decoration: underline;
  cursor: pointer;
`;

const dropDownStyle = {
  borderRadius: '5px',
  height: '45px',
  border: `2px solid ${theme.colors.secondary.lighter}`,
  backgroundColor: theme.colors.miscellaneous.white,
};

const ServicesFilter = () => {
  const { serviceTypes, serviceCategories } = useSelector((state) => ({
    serviceTypes: state?.serviceTypes,
    serviceCategories: state?.serviceCategories,
  }));

  const [typesOptions, setTypeOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const { addToast } = useToasts();
  const dispatch = useDispatch();

  useEffect(() => {
    setTypeOptions(convertHashMapToArray(serviceTypes.value));
  }, [serviceTypes.isLoading]);

  useEffect(() => {
    const categories = convertHashMapToArray(serviceCategories.value);
    const filteredCategories = categories.filter((category) => category?.type_id === selectedType[0]?._id);
    setCategoryOptions(filteredCategories);
    setSelectedCategory([]);
  }, [selectedType]);

  useEffect(() => {
    const filterServices = async ({ typeId, categoryId, addToast }) => {
      const services = await fetchServiceByTypeAndCategory({
        typeId,
        categoryId,
        addToast,
      });
      dispatch(setServices(services));
    };
    try {
      if (selectedType[0]?._id || selectedCategory[0]?._id) {
        filterServices({ typeId: selectedType[0]?._id, categoryId: selectedCategory[0]?._id, addToast });
      }
    } catch (error) {
      errorLogger(error, addToast);
    }
  }, [selectedType, selectedCategory]);

  const handleResetFilter = () => {
    setSelectedType([]);
    setSelectedCategory([]);
    dispatch(
      fetchServicesAsyncThunk({
        successCallback: () => {},
        errorCallback: (error) => {
          errorLogger(error, addToast);
        },
        value: { config: {} },
      })
    );
  };

  return (
    <Container>
      <Wrapper>
        <Box>
          <Text>Filter Type:</Text>
          <CustomDropDown
            options={typesOptions}
            values={selectedType}
            onChange={(e) => {
              setSelectedType(e);
            }}
            valueField={_ID}
            labelField={TYPE}
            style={dropDownStyle}
          />
        </Box>
        <Box>
          <Text>Filter Category:</Text>
          <CustomDropDown
            options={categoryOptions}
            values={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e);
            }}
            valueField={_ID}
            labelField={CATEGORY}
            style={dropDownStyle}
          />
        </Box>
      </Wrapper>
      <ClearText onClick={handleResetFilter}>Clear Filter</ClearText>
    </Container>
  );
};

export default ServicesFilter;
