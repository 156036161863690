import { useSelector } from 'react-redux';
import { Box, TablePagination } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import {
  StyledHeadTableCell,
  StyledTable,
  StyledTableBody,
  StyledTableContainer,
  StyledTableHead,
  StyledTableHeadRow,
} from '../../utils/styledComponents';
import theme from '../../utils/theme';
import PromotionItem from './PromotionItem';
import { convertHashMapToArray } from '../../utils/helpers/component';
import EmptyMessage from '../EmptyMessage';

const headTableCellStyle = {
  padding: '10px',
  border: 'none',
  color: theme.colors.secondary.light,
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: '400',
};

const Container = styled.div`
  margin-top: 125px;
  padding: 0 15px;
  border: ${(props) => `1px solid ${props.theme.colors.secondary.lighter}`};
  border-radius: 15px;
`;

const EmptyContainerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const Promotions = () => {
  const promotions = useSelector((state) => state?.promotions);
  const announcements = convertHashMapToArray(promotions.value);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <Box>
      {isEmpty(announcements) ? (
        <EmptyMessage messageHeading="No Announcements Found" containerStyle={EmptyContainerStyle} />
      ) : (
        <Container>
          <StyledTableContainer>
            <StyledTable
              sx={{ borderCollapse: 'separate', borderSpacing: '0 0.6rem', width: '100%' }}
              aria-label="customized table"
            >
              <StyledTableHead>
                <StyledTableHeadRow>
                  <StyledHeadTableCell sx={headTableCellStyle}>S/N</StyledHeadTableCell>
                  <StyledHeadTableCell sx={headTableCellStyle}>Garage</StyledHeadTableCell>
                  <StyledHeadTableCell sx={headTableCellStyle}>Title</StyledHeadTableCell>
                  <StyledHeadTableCell sx={headTableCellStyle}>Body</StyledHeadTableCell>
                  <StyledHeadTableCell sx={headTableCellStyle}>Date Time</StyledHeadTableCell>
                </StyledTableHeadRow>
              </StyledTableHead>
              <StyledTableBody>
                {announcements?.map((promotion, index) => (
                  <PromotionItem
                    promotion={promotion}
                    key={promotion?._id}
                    index={index}
                    page={page}
                    rowsPerPage={rowsPerPage}
                  />
                ))}
              </StyledTableBody>
            </StyledTable>
          </StyledTableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            component="div"
            count={announcements.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Container>
      )}
    </Box>
  );
};

export default Promotions;
