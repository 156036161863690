import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { openAddCategoryModal } from '../../utils/redux/modalSlice';
import variants from '../../utils/variants';
import CustomButton from '../Adapters/CustomButton';
import CategoryItem from './CategoryItem';
import { convertHashMapToArray } from '../../utils/helpers/component';
import theme from '../../utils/theme';

const Container = styled.div`
  margin-top: 25px;
  & > div:last-child {
    width: fit-content;
    margin: 0 auto;
  }
`;

const Title = styled.h3`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-lg']};
  line-height: ${(props) => props.theme.lineHeights['text-lg']};
  font-weight: 400;
  margin-bottom: 15px;
`;

const TypesContainer = styled.div`
  padding: 10px 25px;
  background-color: #fafafa;
`;

const Box = styled.div``;

const buttonStyle = {
  border: '1px solid #FAFAFA',
  boxShadow:
    '0px 109.322px 87.4576px rgba(41, 72, 152, 0.05), 0px 70.8569px 51.2194px rgba(41, 72, 152, 0.037963), 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 21.8644px 14.2119px rgba(41, 72, 152, 0.025), 0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296), 0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037)',
  padding: '10px 30px',
  borderRadius: '8px',
  color: theme.colors.primary.main,
  fontWeight: '600',
  cursor: 'pointer',
  margin: '10px',
};

const Categories = (props) => {
  const { serviceCategories } = props;
  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(openAddCategoryModal());
  };

  return (
    <Container>
      <Title>Categories</Title>
      {!serviceCategories?.isLoading && (
        <TypesContainer>
          {convertHashMapToArray(serviceCategories?.value).map((category) => (
            <CategoryItem key={category._id} category={category} />
          ))}
        </TypesContainer>
      )}
      <Box>
        <CustomButton variant={variants.secondary} onClick={handleOpen} customStyle={buttonStyle}>
          Create New category
        </CustomButton>
      </Box>
    </Container>
  );
};

Categories.propTypes = {
  serviceCategories: PropTypes.object,
};

export default Categories;
