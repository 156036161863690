export const EMAIL = 'email';

export const OTP = 'otp';

export const REDUX_PERSIST = 'redux_persist';

export const NUMBER = 'number';

export const CURRENCY = 'currency';

export const DUE = 'Due';

export const PAID = 'Paid';

export const BOOKINGS_ALL = 'bookingsAll';

export const BOOKINGS_DROPPED_OFF = 'bookingsDroppedOff';

export const BOOKINGS_IN_SERVICE = 'bookingsInService';

export const BOOKINGS_PICKUP = 'bookingsPickup';

export const BOOKINGS_COMPLETED = 'bookingsCompleted';

export const DRAGGED_BOOKING_SOURCE = 'draggedBookingSource';

export const ONLINE = 'online';

export const OFFINE = 'cash';

export const TEXT_MESSAGE_TYPE = 0;

export const IMAGE_FILE_MESSAGE_TYPE = 1;

export const VIDEO_FILE_MESSAGE_TYPE = 2;

export const APPLICATION_FILE_MESSAGE_TYPE = 3;

export const IMAGE_FILE_TYPE = 'image';

export const VIDEO_FILE_TYPE = 'video';

export const APPLICATION_FILE_TYPE = 'application';

export const VEHICLE_IN_SERVICE = 'vehiclesInService';

export const VEHICLE_CHECKED_IN = 'vehiclesCheckedIn';

export const APPOINTMENTS_BOOKED = 'appointmentsBooked';

export const TOTAL_REVENUE = 'totalRevenue';

export const TYPE = 'type';

export const CATEGORY = 'category';

export const _ID = '_id';

export const ADD = 'add';

export const UPDATE = 'UPDATE';

export const BUTTON = 'button';

export const PERCENTAGE = 'percentage';

export const AMOUNT = 'amount';

export const SUBMIT = 'submit';

export const DAILY = 'daily';

export const WEEKLY = 'weekly';

export const MONTHLY = 'monthly';
