import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { disableBodyScroll, enableBodyScroll } from '../helpers/component';

const disableBodyScollHook = () => {
  const navbarVisibility = useSelector((state) => state?.helpers?.navbarVisibility);

  useEffect(() => {
    if (navbarVisibility) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
  }, [navbarVisibility]);
};

export default disableBodyScollHook;
