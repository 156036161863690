import styled from 'styled-components';
import { useSelector } from 'react-redux';

import NavSidebarSection from './NavSidebarSection';
import NavSidebarDrawerIcon from './NavSidebarDrawerIcon';
import disableBodyScollHook from '../../utils/hooks/disableBodyScollHook';

const Container = styled.aside`
  height: calc(100vh - 30px);
  width: ${(props) => (props.navbarVisibility ? '280px' : '90px')};
  position: fixed;
  top: 15px;
  left: 15px;
  transition: width 0.2s ease-in;
  z-index: 99;
`;

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.secondary.main};
  padding: 20px 20px;
  width: 100%;
  height: 100%;
  border-radius: 16px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${(props) => (props.navbarVisibility ? 'flex-start' : 'center')};
  height: 85%;
  @media screen and (max-width: 1200px) {
    height: 82.5%;
  }
`;

const Bottom = styled.div`
  height: 15%;
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-width: 1200px) {
    height: 17.5%;
  }
`;

const NavSidebar = () => {
  const navbarVisibility = useSelector((state) => state?.helpers?.navbarVisibility);

  disableBodyScollHook();

  return (
    <Container navbarVisibility={navbarVisibility}>
      <Wrapper>
        <Top navbarVisibility={navbarVisibility}>
          <NavSidebarSection />
        </Top>
        <Bottom />
      </Wrapper>
      <NavSidebarDrawerIcon />
    </Container>
  );
};

export default NavSidebar;
