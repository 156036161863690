const { createSlice } = require('@reduxjs/toolkit');
const { helpersInitialState } = require('./states');

const initialState = helpersInitialState;

const helperSlice = createSlice({
  name: 'helpers',
  initialState,
  reducers: {
    updateScreenWidth: (state) => {
      state.screenWidth = window.innerWidth;
    },
    openNavbar: (state) => {
      state.navbarVisibility = true;
    },
    closeNavBar: (state) => {
      state.navbarVisibility = false;
    },
  },
});

export const { updateScreenWidth, openNavbar, closeNavBar } = helperSlice.actions;

export default helperSlice.reducer;
