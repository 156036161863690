import styled from 'styled-components';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useToasts } from 'react-toast-notifications';

import searchIcon from '../../assets/images/svg/search.svg';
import apiInstance from '../../utils/apiInstance';
import theme from '../../utils/theme';

const LocationInputContainer = styled.div`
  position: relative;
`;

const LocationInput = styled.input`
  width: 100%;
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  background-color: ${(props) => props.theme.backgroundColors.blueCyan1};
  border: ${(props) => `2px solid ${props.theme.colors.miscellaneous.white}`};
  padding: 0 40px 0 5px;
  height: 45px;
  border-radius: 5px;
  &:focus {
    outline: ${(props) => `2px solid ${props.theme.colors.primary.main}`};
    border: 1px solid transparent !important;
  }
`;

const ResponseLocations = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  border: ${(props) => `2px solid ${props.theme.colors.primary.main}`};
  width: 100%;
  position: absolute;
  top: 50px;
  left: 0;
  height: ${(props) => props.height};
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.secondary.light};
    border-radius: 3px;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.backgroundColors.blueCyan1};
  }
  & {
    scrollbar-face-color: grey;
    scrollbar-track-color: grey;
  }
`;

const Wrapper = styled.div``;

const ResponseLocationItemContainer = styled.div`
  padding: 10px 0;
  display: flex;
  cursor: pointer;
`;

const ResponseLocationItem = styled.p`
  margin-bottom: 10px;
  font-weight: 400;
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-family: ${(props) => props.theme.fontFamilies.lato};
`;

const Hr = styled.hr``;

const Image = styled.img`
  margin: 0 5px 0 0;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
`;

const closeIconStyle = {
  color: theme.colors.secondary.light,
  position: 'absolute',
  right: '5px',
  top: '50%',
  transform: 'translateY(-50%)',
  cursor: 'pointer',
  fontSize: '24px',
  borderRadius: '50%',
  border: `1px solid ${theme.colors.secondary.light}`,
  padding: '2.5px',
};

const GoogleSeachPlaces = (props) => {
  const {
    containerStyle,
    inputStyle,
    closeIconCustomStyle,
    searchIconCustomStyle,
    debounce,
    callback,
    defaultSearchKey,
    cancelHandlerCallback,
    ...rest
  } = props;

  const [search, setSearch] = useState('');
  const [defaultSearch, setDefaultSearch] = useState(defaultSearchKey);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [resLocationList, setResLocationList] = useState([]);

  const { addToast } = useToasts();

  const handleLocationSelect = async (item) => {
    try {
      const res = await apiInstance.get(`search/searchByPlaceId?place_id=${item.place_id}`);
      const data = {
        description: item?.description,
        formattedAddress: res?.data?.content?.formattedAddress,
        geometry: {
          ...res.data.content.geometry,
        },
        addressParts: res?.data?.content?.addressParts,
        placeId: item?.place_id,
      };
      callback(data);
      setResLocationList([]);
      setShowSearchResults(false);
      setSearch('');
      setDefaultSearch(item?.description);
    } catch (err) {
      addToast(err.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const cancelHandler = () => {
    setResLocationList([]);
    setShowSearchResults(false);
    setSearch('');
    setDefaultSearch('');
    cancelHandlerCallback();
  };

  const handleLocationInput = async () => {
    try {
      const res = await apiInstance.get(`search/searchByQuery?query=${search}`);
      if (res?.data?.content?.predictions?.length > 0) {
        setShowSearchResults(true);
        setResLocationList(res.data.content.predictions);
      }
    } catch (err) {
      addToast(err.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  useEffect(() => {
    let timeOutId;
    if (search) {
      timeOutId = setTimeout(handleLocationInput, debounce || 350);
    }
    return () => {
      clearInterval(timeOutId);
    };
  }, [search]);

  return (
    <LocationInputContainer style={containerStyle}>
      <LocationInput
        style={inputStyle}
        onChange={(e) => {
          if (e.target.value) {
            setSearch(e.target.value);
          } else {
            cancelHandler();
          }
        }}
        value={search || defaultSearch}
        spellCheck="false"
        placeholder="Search for location"
        {...rest}
      />
      {search.length > 0 || defaultSearch.length > 0 ? (
        <CloseIcon style={{ ...closeIconStyle, ...closeIconCustomStyle }} onClick={cancelHandler} />
      ) : (
        <Image src={searchIcon} style={{ ...searchIconCustomStyle }} alt="search icon" />
      )}
      {showSearchResults && (
        <ResponseLocations height={resLocationList.length > 2 ? '175px' : '75px'}>
          {resLocationList.map(
            (item, index) =>
              item.place_id && (
                <Wrapper
                  onClick={() => {
                    handleLocationSelect(item);
                  }}
                  key={item.description}
                >
                  <ResponseLocationItemContainer>
                    <ResponseLocationItem key={index}>{item.description}</ResponseLocationItem>
                  </ResponseLocationItemContainer>
                  {resLocationList.length > 1 && <Hr />}
                </Wrapper>
              )
          )}
        </ResponseLocations>
      )}
    </LocationInputContainer>
  );
};

GoogleSeachPlaces.propTypes = {
  containerStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  closeIconCustomStyle: PropTypes.object,
  searchIconCustomStyle: PropTypes.object,
  debounce: PropTypes.number,
  callback: PropTypes.func,
  defaultSearchKey: PropTypes.string,
  cancelHandlerCallback: PropTypes.func,
};

GoogleSeachPlaces.defaultProps = {
  containerStyle: {},
  inputStyle: {},
  closeIconCustomStyle: {},
  searchIconCustomStyle: {},
  debounce: 300,
  callback: () => {},
  defaultSearchKey: '',
  cancelHandlerCallback: () => {},
};

export default GoogleSeachPlaces;
