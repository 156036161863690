import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import apiInstance from '../../utils/apiInstance';
import { successToastOptions } from '../../utils/constants';
import { errorLogger } from '../../utils/helpers/component';
import theme from '../../utils/theme';
import variants from '../../utils/variants';
import CustomButton from '../Adapters/CustomButton';
import CustomInput from '../Adapters/CustomInput';
import { closeAddTypeModal } from '../../utils/redux/modalSlice';
import { fetchTypesAsync } from '../../utils/redux/typesSlice';

const Container = styled.div``;

const Form = styled.form``;

const Label = styled.p`
  color: ${(props) => props.theme.colors.secondary.darker};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  line-height: ${(props) => props.theme.lineHeights['text-base']};
  font-weight: 600;
  margin-bottom: 15px;
`;

const createButtonStyle = {
  backgroundColor: theme.colors.primary.main,
  border: 'none',
  boxShadow:
    '0px 109.322px 87.4576px rgba(41, 72, 152, 0.05), 0px 70.8569px 51.2194px rgba(41, 72, 152, 0.037963), 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 21.8644px 14.2119px rgba(41, 72, 152, 0.025), 0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296), 0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037)',
  padding: '10px 30px',
  borderRadius: '8px',
  color: theme.colors.miscellaneous.white,
  fontWeight: '600',
  cursor: 'pointer',
  margin: '10px',
};

const cancelButtonStyle = {
  border: '1px solid #D0D5DD',
  boxShadow:
    '0px 109.322px 87.4576px rgba(41, 72, 152, 0.05), 0px 70.8569px 51.2194px rgba(41, 72, 152, 0.037963), 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 21.8644px 14.2119px rgba(41, 72, 152, 0.025), 0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296), 0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037)',
  padding: '10px 30px',
  borderRadius: '8px',
  color: theme.colors.secondary.darker,
  fontWeight: '600',
  cursor: 'pointer',
  margin: '10px',
};

const inputStyle = { height: '50px', backgroundColor: '#fff' };

const AddTypeModalContent = () => {
  const [submitLoading, setSubmitLoading] = useState(false);

  const [type, setType] = useState('');
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    try {
      await apiInstance.post('types/create', { type });
      addToast('Added Type Successfully', successToastOptions);
      dispatch(
        fetchTypesAsync({
          successCallback: () => {},
          errorCallback: (error) => {
            errorLogger(error, addToast);
          },
          value: { config: {} },
        })
      );
      dispatch(closeAddTypeModal());
      setSubmitLoading(false);
    } catch (error) {
      errorLogger(error, addToast, () => {
        setSubmitLoading(false);
      });
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Label>Type Name</Label>
        <CustomInput
          required
          placeHolder="Name"
          style={inputStyle}
          value={type}
          onChange={(e) => {
            setType(e.target.value);
          }}
        />
        <Grid container justifyContent="center" style={{ marginTop: '50px' }}>
          <CustomButton variant={variants.secondary} customStyle={cancelButtonStyle} type="button">
            Cancel
          </CustomButton>
          <CustomButton
            type="submit"
            variant={variants.primary}
            loading={submitLoading}
            customStyle={createButtonStyle}
          >
            Create
          </CustomButton>
        </Grid>
      </Form>
    </Container>
  );
};

export default AddTypeModalContent;
