import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { closeNavBar, openNavbar } from '../../utils/redux/helpersSlice';

const DrawerIcon = styled.div`
  background-color: #fff;
  border-radius: 11px;
  cursor: pointer;
  display: grid;
  height: 22px;
  position: absolute;
  place-items: center;
  top: 7%;
  right: -10px;
  width: 22px;
`;

const NavSidebarDrawerIcon = () => {
  const dispatch = useDispatch();
  const navbarVisibility = useSelector((state) => state?.helpers?.navbarVisibility);

  const handleOpenNavbar = (e) => {
    e.stopPropagation();
    dispatch(openNavbar());
  };

  const handleCloseNavbar = (e) => {
    e.stopPropagation();
    dispatch(closeNavBar());
  };
  return navbarVisibility ? (
    <DrawerIcon onClick={handleCloseNavbar}>
      <KeyboardArrowLeftIcon sx={{ fontSize: '18px' }} />
    </DrawerIcon>
  ) : (
    <DrawerIcon onClick={handleOpenNavbar}>
      <KeyboardArrowRightIcon sx={{ fontSize: '18px' }} />
    </DrawerIcon>
  );
};

export default NavSidebarDrawerIcon;
