import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Navigate } from 'react-router-dom';

import useScrollToTop from './utils/hooks/useScrollToTop';

const ProtectedRoute = (props) => {
  useScrollToTop();
  const user = useSelector((state) => state?.user);
  const { children } = props;
  return !user?.isLoggedIn ||
    !user.accessToken ||
    !user?.refreshToken ||
    !user?.userDetails ||
    isEmpty(user?.userDetails) ? (
    <Navigate to="/login" />
  ) : (
    children
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ProtectedRoute;
