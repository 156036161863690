import styled from 'styled-components';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useToasts } from 'react-toast-notifications';

import Page from '../components/Page';
import variants from '../utils/variants';
import theme from '../utils/theme';
import CustomButton from '../components/Adapters/CustomButton';
import PromocodesList from '../components/Promocodes/PromocodesList';
import { convertHashMapToArray, errorLogger } from '../utils/helpers/component';
import EmptyMessage from '../components/EmptyMessage';
import { fetchPromocodesAsync } from '../utils/redux/promocodesSlice';

const Container = styled.div``;

const buttonStyle = {
  backgroundColor: theme.colors.primary.main,
  border: 'none',
  boxShadow:
    '0px 109.322px 87.4576px rgba(41, 72, 152, 0.05), 0px 70.8569px 51.2194px rgba(41, 72, 152, 0.037963), 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 21.8644px 14.2119px rgba(41, 72, 152, 0.025), 0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296), 0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037)',
  padding: '15px 30px',
  borderRadius: '8px',
  color: theme.colors.miscellaneous.white,
  fontWeight: '600',
  cursor: 'pointer',
};

const EmptyContainerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const PromoCodes = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  useEffect(() => {
    dispatch(
      fetchPromocodesAsync({
        successCallback: () => {},
        errorCallback: (error) => {
          errorLogger(error, addToast);
        },
        value: { config: {} },
      })
    );
  }, []);

  const promocodes = useSelector((state) => state?.promoCodes);

  const promocodesList = useMemo(() => convertHashMapToArray(promocodes.value), [promocodes.value]);

  return (
    <Page variant={variants.general} header="Promo Codes" title="Promo Codes" loading={promocodes.isLoading}>
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '30px' }}>
          <Link to={`/promo-codes/createPromoCode`}>
            <CustomButton type="button" customStyle={buttonStyle}>
              +&nbsp;&nbsp;&nbsp;CREATE PROMOCODE
            </CustomButton>
          </Link>
        </Box>
        {isEmpty(promocodesList) ? (
          <EmptyMessage messageHeading="No Promocodes Found" containerStyle={EmptyContainerStyle} />
        ) : (
          <PromocodesList promocodes={convertHashMapToArray(promocodes?.value)} />
        )}
      </Container>
    </Page>
  );
};
export default PromoCodes;
