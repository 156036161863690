import styled from 'styled-components';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { throttle } from 'lodash';
import { useDispatch } from 'react-redux';

import profileIcon from '../../assets/images/svg/profile.svg';
import { openUserProfileModal } from '../../utils/redux/modalSlice';

const Container = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  height: 100px;
  width: 100%;
  padding: 35px 20px 0 145px;
  background-color: ${(props) => props.theme.backgroundColors.blueCyan1};
  z-index: 95;
  @media screen and (max-width: 1200px) {
    padding: 35px 20px 0 120px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div``;

const Right = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

const Heading = styled.h2`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-4xl']};
  line-height: ${(props) => props.theme.lineHeights['text-4xl']};
  font-weight: 400;
`;

const CurrentTime = styled.h4`
  color: ${(props) => props.theme.colors.primary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-2xl']};
  line-height: ${(props) => props.theme.lineHeights['text-2xl']};
  font-weight: 400;
`;

const CurrentDate = styled.h4`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  line-height: ${(props) => props.theme.lineHeights['text-sm']};
  font-weight: 500;
`;

const UserProfileIcon = styled.div`
  display: grid;
  place-items: center;
`;

const Image = styled.img``;

const dateTime = moment(new Date());
const tempCurrentTime = dateTime.format(`hh:mm A`);
const currentDate = dateTime.format('DD MMM YYYY');

const NavbarHeader = (props) => {
  const { header } = props;

  const [currentTime, setCurrentTime] = useState(tempCurrentTime);
  const dispatch = useDispatch();

  useEffect(() => {
    const intervalId = setInterval(() => {
      throttle(() => {
        const dateTime = moment(new Date());
        const tempCurrentTime = dateTime.format(`hh:mm A`);
        setCurrentTime(tempCurrentTime);
      }, 1000)();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleOpenProfileDrawer = () => {
    dispatch(openUserProfileModal());
  };

  return (
    <Container>
      <Wrapper>
        <Left>
          <Heading>{header}</Heading>
        </Left>
        <Right>
          <CurrentTime>{currentTime}</CurrentTime>
          <CurrentDate>{currentDate}</CurrentDate>
          <UserProfileIcon onClick={handleOpenProfileDrawer} style={{ cursor: 'pointer' }}>
            <Image src={profileIcon} loading="lazy" />
          </UserProfileIcon>
        </Right>
      </Wrapper>
    </Container>
  );
};

NavbarHeader.propTypes = {
  header: PropTypes.string,
};

NavbarHeader.defaultProps = {
  header: '',
};

export default NavbarHeader;
